import './style.scss'


interface IbuttonTriggerToggleSlide {
    position : string
}
const ButtonTriggerToggleSlide = (prop: IbuttonTriggerToggleSlide) =>{

    return (
        <>
        {
        prop.position === `right`?
        <div className= 'preview-item-right'>
            <div className="avatar"></div>
            <div className="message-box"> </div>
        </div>
        :
        prop.position === `left`?
        <div className= 'preview-item-left'>
            <div className="avatar"></div>
            <div className="message-box"> </div>
        </div>
        :
        prop.position === `left-up`?
        <div className= 'preview-item-left-up'>
            <div className="avatar"></div>
            <div className="message-box"> </div>
        </div>
        :
        prop.position === `right-up`?
        <div className= 'preview-item-right-up'>
            <div className="avatar"></div>
            <div className="message-box"> </div>
        </div>
        :
        ""
        
        
        }
        </>
    )
}

export default ButtonTriggerToggleSlide