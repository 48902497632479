import { CardService, InputBoxSearch, PaginationResponsiveCustomfrom } from 'components';
import { useEffect, useState } from 'react';
import './style.scss';
import { useServices } from 'services';
import moment from 'moment';
import { IallServiceCall } from 'interfaces';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/esm/Modal';
import Table from 'react-bootstrap/Table';
import { BsCloudSlash } from 'react-icons/bs';
import { HiOutlineCalendar } from 'react-icons/hi';

const BookedServiceCalls = (prop: any) => {
    const [bookedServiceCalls, setBookedServiceCalls] = useState<any>([]);
    const { api } = useServices();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [totalpages, setTotalpages] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [startDate, setStartDate] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [periodTime, setPeriodTime] = useState<any>();
    const [description, setDescription] = useState<string>('');

    const calulationPeriod = (startTime: any, endTime: any) => {
        let startDate: any = moment(startTime);
        let endDate: any = moment(endTime);
        let duration: any = moment.duration(endDate.diff(startDate));
        let mins: any = duration.asMinutes();

        return mins;
    };

    const periods30Days = () => {
        let today = new Date();
        let next30days = new Date(new Date().setDate(today.getDate() + 30));
        return {
            today: moment(today).format('YYYY-MM-DDThh:mm'),
            next30: moment(next30days).format('YYYY-MM-DDThh:mm')
        };
    };

    useEffect(() => {
        let today = periods30Days().today;
        let next30days = periods30Days().next30;
        (async () => {
            let result = await api.serviceCall.getAllServiceCallsCalendar(
                today,
                next30days,
                search,
                pageNumber,
                pageSize
            );

            if (result.data) {
                let bookedServiceCall = result.data.list;
                setBookedServiceCalls(bookedServiceCall);
                setTotalpages(result.data.totalPages);
                setPageNumber(result.data.pageNumber);
            }
        })();
    }, [pageNumber, prop.refreshBookingCall]);

    useEffect(() => {
        if (!search) return;
        let today = periods30Days().today;
        let next30days = periods30Days().next30;
        const delayDebounceFn = setTimeout(async () => {
            let result = await api.serviceCall.getAllServiceCallsCalendar(
                today,
                next30days,
                search,
                pageNumber,
                pageSize
            );
            let bookedServiceCall = result.data.list;
            setBookedServiceCalls(bookedServiceCall);
            setTotalpages(result.data.totalPages);
            setPageNumber(result.data.pageNumber);
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const readmorePopup = (item: IallServiceCall) => {
        setIsShowModal(true);
        setTitle(item.name);
        setStartDate(moment(item.startTime).format('D MMM YYYY'));
        setStartTime(item.startTime.split('T')[1].substring(0, 5));
        setPeriodTime(calulationPeriod(item.startTime, item.endTime));
        let desc: any = item.description ? parse(item.description.toString()) : 'None';
        setDescription(desc);
    };

    return (
        <>
            <div className="booked-service-calls-page">
                <div className="card border-primary booked-service-calls">
                    <div className="card-body text-primary booked-service-calls-search">
                        <div className="title-booked-service">Booked Sales Meeting</div>
                        <div className="input-group mb-4 ">
                            <InputBoxSearch changeFn={(e) => setSearch(e.target.value)} placeholder="Search..." />
                        </div>
                        {bookedServiceCalls && bookedServiceCalls.length ? (
                            <div className="row" style={{ paddingLeft: 15 }}>
                                {bookedServiceCalls.map((item: IallServiceCall, index: number) => (
                                    <CardService
                                        key={index}
                                        clientName={item.name}
                                        date={moment(item.startTime).format('D MMM YYYY')}
                                        time={item.startTime.split('T')[1].substring(0, 5)}
                                        periodTime={calulationPeriod(item.startTime, item.endTime)}
                                        paragraph={item.description ? parse(item.description.toString()) : 'None'}
                                        amountClip={3}
                                        Icon={`${process.env.PUBLIC_URL}/assets/images/search-icon-card.png`}
                                        readmoreToPopup={() => readmorePopup(item)}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="empty-placeholder">
                                <HiOutlineCalendar color="#ddd" size={64} />
                                <h5>Currently booked sales meeting</h5>
                            </div>
                        )}
                        <br />
                        <PaginationResponsiveCustomfrom
                            position={`right`}
                            pageNumber={pageNumber}
                            totalpages={totalpages}
                            onPageChanged={setPageNumber}
                        />
                    </div>
                </div>
                <Modal show={isShowModal} onHide={() => setIsShowModal(false)}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="title-booked-service-calls">Title : {title}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive="sm" className="table-booked-service-calls-modal">
                            <tbody>
                                <tr>
                                    <td className="label-booked-service-calls">Start Date</td>
                                    <td className="content-booked-service-calls">{startDate}</td>
                                </tr>
                                <tr>
                                    <td className="label-booked-service-calls">Start Time</td>
                                    <td className="content-booked-service-calls">{startTime}</td>
                                </tr>
                                <tr>
                                    <td className="label-booked-service-calls">Period Time</td>
                                    <td className="content-booked-service-calls">{periodTime} Min</td>
                                </tr>
                                <tr>
                                    <td className="label-booked-service-calls">Description</td>
                                    <td className="content-booked-service-calls">
                                        {' '}
                                        <div className="description-box">{description}</div>{' '}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="close-button-booked-service-calls btn" onClick={() => setIsShowModal(false)}>
                            <span>Close</span>
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default BookedServiceCalls;
