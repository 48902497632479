import React from 'react';

const InteractisUploadRecordAudio = (props) => {

    return (
        <>
            <div className="transition ease-in duration-700 sm:block interactis-container sm:max-w-4xl max-h-56 fixed bg-gray-200 sm:w-full  m-auto inset-0">
                <div className="w-full h-full bg-red text-center tracking-widest p-16">
                    <div className="text-2xl">We are uploading the recording to the cloud, <br/>please do not close your browser</div>
                </div>
            </div>
        </>
    );
};
export default InteractisUploadRecordAudio;
