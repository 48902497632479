import { Idropdown, IdropdownOnChange } from 'interfaces';
import React, { useState, useContext, useRef, useEffect } from 'react';
import './style.scss';

function DropdownOnchange(prop: IdropdownOnChange) {
    // const logoImage = `${process.env.PUBLIC_URL}/assets/images/${prop.image}`;

    const [data, setData] = useState<any>([]);
    const [selected, setSelected] = useState<number>();

    const classes = {
        selectOption: {
            border: prop.border,
        },
    };

    useEffect(() => {
        setData(prop.data);
        if(prop.data.length > 0 ){
            let defaultValue: any = prop.data.find(item => item.defaultValue === true);
            if (defaultValue) {
                setSelected(defaultValue.value);
            }else{
                let role = prop.data.find(item => item.defaultValue === false);
                setSelected(role.value)
            }
        }
    }, [prop.data]);

    return (
        <>
            {
                <select
                    disabled = {prop.isReadOnly}
                    style={classes.selectOption}
                    className="form-control custom-dropdown"
                    defaultValue={selected}
                    value={selected}
                    onChange={(e: any) => {
                        setSelected(e.target.value);
                        prop.optionOnChange(e);
                    }}
                >
                    {prop.data.map((item: Idropdown, index: number) => (
                        <option key={index} value={item.value}>
                            {item.text}
                        </option>
                    ))}
                </select>
            }
        </>
    );
}

export default DropdownOnchange;
