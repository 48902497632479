import { CardStatistics, InvitationLink } from 'components';

const DashboardStatisticWidgets = (props) => {
    const { statistic } = props;

    return (
        <div className="row container-cards">
            <CardStatistics
                title="Incoming calls"
                summary={statistic.incomingCall}
                summarycolor="#63E2F1"
                bgColor="#63E2F126"
                icon={'coming-call'}
                colorIcon="#63E2F1"
                height={47}
                width={47}
            />
            <CardStatistics
                title="Average call time (min)"
                summary={statistic.averageCallTime}
                summarycolor="#28C66F"
                bgColor="#28C66F26"
                icon={'coming-call'}
                colorIcon="#28C66F"
                height={47}
                width={47}
            />
            <CardStatistics
                title="Total in queue"
                summary={statistic.totalInQueue}
                summarycolor="#EC5453"
                bgColor="#EC545326"
                colorIcon="#EC5453"
                icon={'user'}
                height={47}
                width={47}
            />

            <InvitationLink  />
        </div>
    );
};

export default DashboardStatisticWidgets;
