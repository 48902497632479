import { useState, useEffect, useRef, useCallback } from 'react';
import { SketchPicker } from 'react-color';
import './style.scss';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiCheck } from 'react-icons/bi';
import { CiPickerHalf } from 'react-icons/ci';
import useClickOutside from './useClickOutside';

interface Props {
    title?: string;
    subtitle?: string;
    debug?: boolean;
    color?: string;
    onChange?: Function;
}

function CardColorPicker(props: Props) {
    const { title, subtitle, onChange, color, debug = true } = props;
    const colorPickerRef = useRef();

    const [selectedColor, setSelectedColor] = useState<string>(color);
    const [showColorPicker, setShowColorPicker] = useState<any>(false);
    const baseColors = ['#c5c5c5', '#28c66f', '#ffab00', '#ec5453', '#6259ce'];

    const [sketchPickerColor, setSketchPickerColor] = useState({
        r: '98',
        g: '89',
        b: '206',
        a: '1'
    });
    // destructuring rgba from state
    const { r, g, b, a } = sketchPickerColor;

    const toggleColorPicker = (e) => {
        document.querySelectorAll('.color-picker').forEach((e) => {
            e.classList.remove('show');
        });

        setTimeout(() => {
            setShowColorPicker(!showColorPicker);
        }, 100);
    };

    useEffect(() => {
        if (color) {
            setSelectedColor(color);
        }
    }, [props]);

    useEffect(() => {
        if (selectedColor) {
            if (onChange) {
                onChange(selectedColor);
            }
        }
    }, [selectedColor]);

    const close = useCallback(() => setShowColorPicker(false), []);
    useClickOutside(colorPickerRef, close);

    function wc_hex_is_light(color) {
        if (!color) return false;
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    }

    return (
        <>
            <div className="card container-card-custom card-color-picker">
                <div className="card-body">
                    {title && <div className="title">{title}</div>}
                    {subtitle && <div className="sub-title">{subtitle}</div>}
                    <div className={`container-input-class`}>
                        <div className="base-colors">
                            {baseColors.map((c, i) => (
                                <div
                                    key={`color_${i}`}
                                    style={{ backgroundColor: c }}
                                    onClick={() => setSelectedColor(c)}>
                                    {c == selectedColor ? <BiCheck color={'white'} /> : <></>}
                                </div>
                            ))}
                            <div>
                                <span style={{ backgroundColor: selectedColor }}>
                                    {baseColors.indexOf(selectedColor) === -1 ? <BiCheck color={wc_hex_is_light(selectedColor) ? 'black' : 'white'} /> : <></>}
                                </span>
                                <button type="button" className="btn btn-icon" onClick={(e) => toggleColorPicker(e)}>
                                    <CiPickerHalf />
                                </button>
                            </div>
                        </div>

                        {debug ? (
                            <small className="debug" style={{ fontWeight: '700' }}>
                                {selectedColor}
                            </small>
                        ) : (
                            <></>
                        )}

                        <div ref={colorPickerRef} className={`color-picker ${showColorPicker ? 'show' : ''}`}>
                            <div className="toolbar">
                                <button
                                    type="button"
                                    className="btn btn-icon"
                                    onClick={() => setShowColorPicker(false)}>
                                    <AiOutlineCloseCircle size={20} />
                                </button>
                            </div>
                            <SketchPicker
                                onChange={(color) => {
                                    setSketchPickerColor(color.rgb);
                                    setSelectedColor(
                                        `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                                    );
                                }}
                                color={sketchPickerColor}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardColorPicker;
