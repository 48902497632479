import React from 'react';

import IcomoonReact from 'icomoon-react';
import iconSet from '../../assets/weface-icon/selection.json';

const Icon: React.FC<{
    color?: string;
    size: string | number;
    icon: string;
    className?: string;
    style?: any;
}> = props => {
    const { color, size = '100%', icon, className = '', style = {} } = props;
   // console.log('color', color);
    return (
        <IcomoonReact
            className={className}
            iconSet={iconSet}
            color={color}
            size={size}
            icon={icon}
            style={style}
        />
    );
};

export default Icon;
