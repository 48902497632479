import { AuthApi } from './auth.service';
import { CallSettingApi } from './setting.service';
import { DashboardApi } from './dashboard.service';
import { UserApi } from './user.service';
import { ServiceCallsApi } from './servicecalls.service';
import { CallingApi } from './calling.service';
import { InsightApi } from './insight.service';
import { TriggerApi } from './trigger.service';
import { LocalizationsApi } from './localization.service';
import { ChatApi } from './chat.service';

export class ApiService {
    private inited = false;

    auth: AuthApi;
    user: UserApi;
    callSetting: CallSettingApi;
    dashboard: DashboardApi;
    serviceCall: ServiceCallsApi;
    calling: CallingApi;
    chat: ChatApi;
    insight: InsightApi;
    localization: LocalizationsApi;
    trigger: TriggerApi;

    constructor () {
        this.auth = new AuthApi();
        this.user = new UserApi();
        this.callSetting = new CallSettingApi();
        this.dashboard = new DashboardApi();
        this.serviceCall = new ServiceCallsApi();
        this.calling = new CallingApi();
        this.chat = new ChatApi();
        this.insight = new InsightApi();
        this.localization = new LocalizationsApi();
        this.trigger = new TriggerApi();
    }

    init = async (): Promise<void> => {
        if (!this.inited) {
            // your code ...

            this.inited = true;
        }
    };
}
