import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
`;

export const ButtonsSpinnerWrapper = styled.div`  
  & .sk-chase {
  width: 20px;
  height: 20px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

& .sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

& .sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

& .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
& .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
& .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
& .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
& .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
& .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
& .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
& .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
& .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
& .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
& .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
& .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}
`;

export const Spinner = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  margin: 100px auto;

  & .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${(props) => props.color || COLORS.primary};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  & .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`;
