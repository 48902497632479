import { axiosPrivate } from 'services/axios.private';

export class DashboardApi {
    getStatistic = async () => {
        const res = await axiosPrivate
            .request({
                url: `/api/v2/dashboard/statistic`,
                method: 'GET'
            })
            .catch((err) => {
                return err;
            });

        return res.data;
    };

    getCurrentVisitor = async () => {
        const res = await axiosPrivate
            .request({
                url: `/api/v2/dashboard/currentVisitors`,
                method: 'GET'
            })
            .catch((err) => {
                return err;
            });

        return res.data;
    };

    generateCallInvitation = async (formData, params) => {
        const res = await axiosPrivate
            .post('/api/v1/invitation-call/command/generate-call-invitation', formData, params)
            .catch((err) => {
                return err;
            });

        return res;
    };

    sendInvitationUsingEmail = async (formData, params) => {
        const res = await axiosPrivate
            .post('/api/v1/invitation-call/command/invitation-using-email', formData, params)
            .catch((err) => {
                return err;
            });

        return res.data;
    };

    sendInvitationUsingSMS = async (formData, params) => {
        const res = await axiosPrivate
            .post('/api/v1/invitation-call/command/invitation-using-sms', formData, params)
            .catch((err) => {
                return err;
            });
        return res.data;
    };
}
