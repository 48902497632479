import { axiosPrivate } from 'services/axios.private';
import { axiosPublic } from 'services/axios.public';

export class CallingApi {
    getChatMessages = async (sessionId) => {
        try {
            var res = await axiosPrivate.get('/api/v2/chats/messages?sessionId=' + sessionId);
            return res.data;
        } catch (error) {
            // console.log('ERROR on get call settings : ', error);
        }
    };

    // loadCallSummary = async hubConnection => {
    //   if (hubConnection) await hubConnection.send('SendCallSummary');
    // };

    checkAuthentication = (connectionHub, accessToken, connectionHubId, saleAgentId) => {
        if (accessToken != null) {
            // console.log('checkAuthentication')
            const addToSalesAgentGroup = async () => {
                // console.log('addToSalesAgentGroup')
                if (connectionHub) await connectionHub.send('AddToSalesAgentsGroup', connectionHubId, saleAgentId);
            };
            addToSalesAgentGroup();
        }
        return accessToken;
    };

    initiatePermissions = async (
        accessToken,
        setPermissions,
        // setCallControl,
        setSaleAgentId,
        setUsersId
    ) => {
        if (accessToken) {
            // apiConfigService
            //     .authen()
            //     .get('/api/v2/frontend/me')
            //     .then((res: any) => {
            //         console.log('initiatePermissions res', res);
            //         if (res.permissions) setPermissions(res.permissions);
            //         setSaleAgentId(res.userId); //id by table Users not have admin id.
            //         setUsersId(res.id); //id by table AspNetUsers.
            //     })
            //     .catch(function (error) {
            //         // console.log('Error :', error);
            //     });
        } else {
            // setCallControl('readytocall');
        }
    };

    getIceServer = () => {
        var data = axiosPrivate.get('/api/v1/webrtc/get-webRTC-server-configuration');
        return data;
    };

    getStaticIceServer = async () => {
        var res = await axiosPrivate.get('/api/v1/webrtc/get-webRTC-static-server-configuration');
        return res.data;
    };

    // getQueueIndex = async (connectionHubId, setGetUserWaitingQueueIndex) => {
    //   try {
    //     var res = await apiConfigService
    //       .authen()
    //       .get('/api/v1/frontend/getUserWaitingQueueIndex?userStartCallId=' + connectionHubId);

    //     setGetUserWaitingQueueIndex(res.data);
    //   } catch (error) {
    //     console.error('ERROR on get user waiting queue index : ', error);
    //   }
    // };

    stopVideoStream = (videoRef, wait, retries, counter) => {
        if (!videoRef.current) {
            if (counter <= retries)
                setTimeout(() => {
                    ++counter;
                    this.stopVideoStream(videoRef, wait, retries, counter);
                }, wait);
        } else {
            videoRef.current.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    };

    initiateUserMediaLocalStream = async (switchCamera = false, video = true, audio = true) => {
        try {
            let facingMode;
            if (video == true) {
                if (switchCamera == true) {
                    facingMode = { facingMode: { exact: 'environment' } };
                } else {
                    facingMode = { facingMode: 'user' };
                }
            } else {
                facingMode = false;
            }
            const stream = await navigator.mediaDevices.getUserMedia({
                video: facingMode,
                audio: audio
            });

            return stream;
        } catch (exception) {
            console.error(exception);
        }
    };

    getServiceCallByMinutes = async (minutes, saleAgentId) => {
        var data = await axiosPrivate.get(
            `/api/v1/service-call/query/service-calls-by-minutes?Minutes=${minutes}&SaleAgentId=${saleAgentId}`
        );
        return data;
    };

    getServiceCallByKey = async (key) => {
        var data = await axiosPrivate
            .get('/api/v1/service-call/query/get-service-call-by-key?Key=' + key)
            .catch((err) => {
                return err;
            });
        return data;
    };

    cancelServiceCall = async (serviceCallId) => {
        var data = await axiosPrivate
            .post('/api/v1/service-call/command/cancel-service-call-by-id', serviceCallId)
            .catch((err) => {
                return err;
            });
        return data;
    };
    uploadAudio = async (blob) => {
        var data = await axiosPrivate.post('/api/v1/callRecord/command/uploadAudio', blob).catch((err) => {
            return err;
        });
        return data;
    };
    getCallInvitationByKey = async (secretCode) => {
        var data = await axiosPrivate
            .get('/api/v1/invitation-call/query/GetCallInvitationByKey?secretCode=' + secretCode)
            .catch((err) => {
                return err;
            });
        return data;
    };

    getCallSession = async (sessionId: string) => {
        var res = await axiosPrivate.get('/api/v2/callQueues/' + sessionId).catch((err) => {
            return err;
        });
        return res.data;
    };

    uploadFile = async (blob: FormData) => {
        const res = await axiosPublic
            .post(`/api/v2/chats/upload`, blob, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .catch((err) => {
                return err;
            });
        return res.data;
    };
}
