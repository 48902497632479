export const CALL_STATUS = {
    WAIT: 'wait',
    CALLING: 'calling',
    READY: 'ready',
    TIMEOUT: 'timeout',
    COMING_CALL: 'comingcall'
};

export const SESSIONSTORAGE_NAME = {
    START_CALL_TIME: 'start-call-time',
    CALL_STATUS: 'call-status',
    CALL_SESSION_ID: 'call-session-id',
    CONNECTIONHUB_ID: 'connection-id',
    USER_ACCEPT_CALL_ID: 'user-accept-call-id',
    USER_ACCEPT_CALL_IFRAME: 'user-accept-call-iframe',
};

export const CALL_ACTION = {
    COMING_CALL: 'comingcall',
    COMING_INVITATION_CALL: 'comingcallfromcode',
    START_CALL: 'startcall',
    SEND_CALL: 'sendcall',
    ACCEPT_CALL: 'acceptcall',
    END_CALL: 'endcall',
    DISMISS: 'dismiss',
    START_SERVICE_CALL: 'startservicecall',
    RECORD: 'recordAudio'
};

export const MESSAGES_ALERT = {
    SAVE_SUCCESS: '{name} has been saved.',
    SAVE_FAILED: 'Cannot save {name}.',
    SAVE_VISIBLE: '{name} has been enabled',
    SAVE_HIDE: '{name} has been disabled',
    COPY_SUCCESS: '{name} has been copied',
    COPY_FAILED: 'Cannot copy {name}',
    SENT_SUCCESS: '{name} has been sent',
    SENT_FAILED: 'Cannot send {name}',
    UPDATE_SUCCESS: '{name} has been updated.',
    UPDATE_FAILED: 'Cannot update {name}.',
    DELETE_SUCCESS: '{name} has been deleted.',
    DELETE_FAILED: 'Cannot delete {name}.',
    LOGIN_FAILED: 'Your username or password is incorrect, please try again.',
    CONVERT_SOUND_FAILED: 'Cannot convert file {name}.',
    ANALYSIS_SENTIMENT_FAILED: 'Cannot analysis sentiment for {name}.',
    DOWNLOAD_SOUND_FAILED: 'Cannot download file for {name}.'
};

export const MessageType = {
    TEXT: 0,
    IMAGE: 1,
    FILE: 2
};

export const USER_TYPE = {
    CLIENT: 0,
    SALES_AGENT: 1
};


export const ORDER_BY = {
    ASC: 'ASC',
    DESC: 'DESC'
};

export const USER_ROLE = {
    SUPERADMIN : 0,
    ADMIN: 1,
    SALES_AGENT: 2
};

export const COLORS = {
    primary: '#6259ce',
    primaryLight: '#dcdaf1',
};
