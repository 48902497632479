import { Icon, ModalDeleteDialogBox, PageSpinner, PaginationResponsiveCustomfrom, TopContentHeader } from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { columnsUser } from 'utils/help';
import { useServices } from 'services';
import { Iusers } from 'interfaces';
import { ToastContainer, toast } from 'react-toastify';
import { isImageType, replaceMessages, toastProperty } from 'utils/help';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { MESSAGES_ALERT, ORDER_BY } from 'utils/constants';
import './style.scss';

const Users = () => {
    const navigate = useNavigate();
    const columns = columnsUser();

    const [dataUser, setDataUser]: any = useState(Array);
    const [isSort, setIsSort]: any = useState<boolean>(false);
    const [pageNumber, setPageNumber]: any = useState<number>(1);
    const [pageSize]: any = useState<number>(window.innerHeight > 900 ? 8 : 6);
    const [columnName, setColumnName]: any = useState<string>('Firstname');
    const [totalpages, setTotalpages]: any = useState<number>(0);
    const [loading, setLoading]: any = useState<boolean>(true);
    const [searchContact, setSeachContact]: any = useState<any>('');
    const [sortDirection, setSortDirection]: any = useState(ORDER_BY.ASC);

    const { api } = useServices();
    const [showModalDeleteUser, setShowModalDeleteUser] = useState<boolean>(false);
    const [userId, setUserId] = useState<number>(0);

    var APIurl = "";
    if (process.env.NODE_ENV !== 'production') {
        APIurl = process.env.REACT_APP_API_URL + "/";
    }
    const unix = Math.round(+new Date() / 1000);

    const handleSorting = async (accessor: any) => {
        let result = await api.user.getUsers(pageNumber, pageSize, sortDirection, accessor, searchContact);
        setDataUser(result.data.list);
    };

    const userAccounts = async () => {
        setLoading(true);
        let result = await api.user.getUsers(pageNumber, pageSize, sortDirection, columnName, searchContact);
        setDataUser(result.data.list);
        setTotalpages(result.data.totalPages);
        setLoading(false);
    };

    useEffect(() => {
        userAccounts();
    }, [pageNumber, searchContact,showModalDeleteUser]);

    const redirectToAddUser = () => {
        navigate('/users/addnewuser', { replace: true });
    };
    const handleDeleteToUser = useCallback((id: any) => {
        setLoading(true);
        (async () => {
            await api.user.deleteUser(id);
            userAccounts();
            setLoading(false);
            toast.success(
                replaceMessages(MESSAGES_ALERT.DELETE_SUCCESS, `The user`),
                toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
            );
            setShowModalDeleteUser(false);
        })();
    }, []);

    const closeRemoveUserModal = () => {
        setShowModalDeleteUser(false);
    };

    return (
        <>
            <NavbarTop />
            <NavbarLeft />
            {loading && <PageSpinner />}
            <ToastContainer />
            <div className="content-page">
                <div className="container-fluid">
                    <TopContentHeader
                        titleOfPage={`USERS`}
                        isSearch={true}
                        placeholderSearch={`Search Contact Name`}
                        functionForSearch={(e) => setSeachContact(e.target.value)}
                        hasButton={true}
                        buttonName={`Add New User`}
                        hasSymbolButton={true}
                        symbolButton={`+`}
                        functionButton={() => redirectToAddUser()}
                    />
                    <div className="table-users">
                        <table className="table-data-user">
                            <thead>
                                <tr>
                                    {columns.map((item: any, index: number) => (
                                        <th
                                            onClick={(e) => {
                                                handleSorting(item.accessor);
                                            }}
                                            key={index}>
                                            {item.header === '' ? <span className="header-user">#</span> : item.header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {dataUser.map((item: Iusers, index: number) => (
                                    <tr key={index}>
                                        <td>
                                            {isImageType(item.imageUrl) ? (
                                                <div
                                                    className="user-profile"
                                                    style={{
                                                        backgroundImage: `url(${APIurl}${item.imageUrl}?v=${unix})`
                                                    }}></div>
                                            ) : (
                                                <div
                                                    className="user-profile"
                                                    style={{
                                                        backgroundImage: `url('assets/icons/empty-img.png')`
                                                    }}></div>
                                            )}
                                        </td>
                                        <td>
                                            {item.firstname} {item.lastname}
                                        </td>
                                        <td>
                                            {item.userRole === 1
                                                ? 'Administrator'
                                                : item.userRole === 2
                                                ? 'Sale Agent'
                                                : 'Super Administrator'}
                                        </td>
                                        <td>{item.phoneNumber}</td>
                                        <td>{item.username}</td>
                                        <td>{moment(item.createdDate).format('D MMM YYYY')}</td>
                                        <td>
                                            <div className="user-edit">
                                                <NavLink to={`/users/edituser/${item.id}/`}>
                                                    <Icon icon={`user-edit`} size={28} color={`#28C66F`} />
                                                </NavLink>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                className="user-delete"
                                                onClick={(e) => {
                                                    setShowModalDeleteUser(true), setUserId(item.id);
                                                }}>
                                                <Icon icon={`bin`} size={28} color={`#EC5453`} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Modal show={showModalDeleteUser} onHide={closeRemoveUserModal}>
                            <ModalDeleteDialogBox
                                removeEvent={() => handleDeleteToUser(userId)}
                                closeModal={closeRemoveUserModal}
                                title={`Deleting User`}
                                textAlert={`Are You Sure You Want To Delete This User?`}
                            />
                        </Modal>
                        <PaginationResponsiveCustomfrom
                            position={`right`}
                            pageNumber={pageNumber}
                            totalpages={totalpages}
                            onPageChanged={setPageNumber}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Users;
