import React, { useEffect, useState, useContext } from 'react';
import './style.scss';
import { SettingContext } from 'contexts';
import { useAudio } from 'hooks';

function InteractisComingCall(props) {
    const { callRequest, enableVideo, enableAudio } = props;

    const [settingContext] = useContext(SettingContext);
    const { timeoutNotAnsweringCall , isPlaysoundOnCall } = settingContext;

    const [show, setShow] = useState(false);
    const isChat = !enableVideo && !enableAudio;

    const audio = useAudio();

    console.log("isPlaysoundOnCall : ", isPlaysoundOnCall)

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
            if (isPlaysoundOnCall){
                console.log("Sound Play")
                audio.play();
            }
            setTimeout(() => {
                setShow(false);
                console.log("Sound Pause")
                audio.pause();
            }, (timeoutNotAnsweringCall * 1000))
        }, 500);

        return () => {
            setShow(false);
            console.log("Sound Pause") 
            audio.pause();
        };
    }, []);

    return (
        <div className={`coming-call ${show ? 'show' : ''}`}>
            <div className="card">
                <div className="card-body">
                    <div className={`icon ${isChat ? 'chat' : ''}`}>
                        {!isChat ? (
                            <>
                                <div>
                                    <img src="/assets/icons/call.gif" />
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <img src="/assets/icons/typing.gif" />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="actions">
                        {!isChat ? <h4>Incoming call..</h4> : <h4>Incoming message..</h4>}
                        <div className="button-groups">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => callRequest('acceptcall')}
                            >
                                {isChat ? (
                                    'Start chat'
                                ) : (
                                    <>
                                        <img src="/assets/icons/accept-xs.png" />
                                        Accept
                                    </>
                                )}
                            </button>
                            {/* <button
                                type="button"
                                className="btn btn-default"
                                onClick={() => { }}
                            >
                                View session
                            </button> */}
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => callRequest('dismiss')}
                            >
                                <img src="/assets/icons/decline-xs.png" /> Decline
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InteractisComingCall;


