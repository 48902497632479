import { axiosPrivate } from 'services/axios.private';

export class InsightApi {
    getInsightData = async (page: number, order: string, orderBy: string, rowsPerPage: number, keyword: string) => {
        const res = await axiosPrivate
            .get('/api/v2/insights', {
                params: {
                    Page: page,
                    SortDirection: order,
                    SortColumn: orderBy,
                    PageSize: rowsPerPage,
                    Search: keyword
                }
            })
            .catch((err) => {
                return err;
            });

        return res.data;
    };

    getInsightById = async (id: any) => {
        const res = await axiosPrivate.get(`/api/v2/insights/${id}`).catch((err) => {
            return err;
        });
        return res.data;
    };

    downloadSoundFile = async (callQueuesId: any, audioFileId: any) => {
        return await axiosPrivate.get('/api/v1/callRecord/query/downloadCallRecord', {
            params: {
                callQueuesId: callQueuesId,
                audioFileId: audioFileId
            },
            responseType: 'blob'
        });
    };

    getCallRecord = async (id: any) => {
        let result = await axiosPrivate.get('/api/v1/callRecord/query/callRecord/' + id).catch((err) => {
            return err;
        });
        return result.data;
    };

    actionQueues = async (formData: any, params: any) => {
        let result = await axiosPrivate
            .post('/api/v1/callRecord/command/actionQueues', formData, params)
            .catch((err) => {
                return err;
            });
        return result.data;
    };

    deleteCallRecord = async (id: any) => {
        let result = await await axiosPrivate.delete(`/api/v1/callRecord/command/delete/${id}`).catch((err) => {
            return err;
        });
        return result.data;
    };
}
export default new InsightApi();
