import Icon from 'components/icon-moon';
import { ConnectionHubContext } from 'contexts';
import { useContext, useEffect, useState, useRef } from 'react';
import { TbScreenShareOff } from 'react-icons/tb';
import { MoonLoader } from 'react-spinners';
import { ButtonCall, ButtonChat, ButtonClose, Loading, PlayerToolbar } from './style-component';
import './style.scss';
import { Replayer } from 'rrweb';
import { Buffer } from 'buffer';
import utilService from '../interactis/untils/interactisService';
import moment from 'moment';
import {
    handleLoadPageEvent,
    isDuplicatedEvent,
    isIgnoredOnRmoteControl,
    onMirror
} from 'components/interactis/untils/replayerMirrorService';

const SessionView = (props) => {
    const { selectedClient } = props;
    const { statusShareScreenClient, setStatusShareScreenClient } = props;

    const [loading, setLoading] = useState(true);

    const [screenWidth, setScreenWidth] = useState(0);
    const [screenHeight, setScreenHeight] = useState(0);
    const [scaleWidth, setScaleWidth] = useState('');

    const [senderId, setSenderId] = useState();
    const [signalData, setSignalData] = useState();
    const [connectingMessage, setConnectingMessage] = useState('');

    const [connectionHubContext] = useContext(ConnectionHubContext);
    const { connectionHub, connectionHubId } = connectionHubContext;

    let player = null;
    let isChunk = false;
    let recordings = [];
    let recentEvent;
    let chunkBufferString = '';
    const peerWeb = useRef();

    const slideAnime = async (setOptions) => {
        const typeSlideDown = 'slideDown';
        const typeSlideUp = 'slideUp';
        const typeSlideToggle = 'slideToggle';

        const defaultOptions = {
            target: false,
            animeType: typeSlideToggle,
            duration: 1000,
            easing: 'ease',
            displayStyle: 'flex',
            callBack: () => {
                console.log('slide on close');
            }
        };

        const options = Object.assign({}, defaultOptions, setOptions);
        const target = options.target;

        if (!target) {
            return;
        }

        let animeType = options.animeType;

        const styles = getComputedStyle(target);
        const textNone = 'none';
        const isDisplayNone = styles.display === textNone;
        if (animeType === typeSlideToggle) {
            animeType = isDisplayNone ? typeSlideDown : typeSlideUp;
        }

        const busyClass = 'is-slide-busy';
        const targetClassList = target.classList;
        const isSlideDown = animeType === typeSlideDown;
        const isSlideUp = animeType === typeSlideUp;
        const isBusy = targetClassList.contains(busyClass);

        if (
            (isSlideUp && (isDisplayNone || isBusy)) ||
            (isSlideDown && (!isDisplayNone || isBusy)) ||
            (!isSlideUp && !isSlideDown)
        ) {
            return;
        }

        targetClassList.add(busyClass);

        const targetStyle = target.style;
        targetStyle.overflow = 'hidden';

        const displayStyle = options.displayStyle;
        if (isSlideDown) {
            targetStyle.display = displayStyle;
        }

        const heightVal = {
            height: target.getBoundingClientRect().height + 'px',
            marginTop: styles.marginTop,
            marginBottom: styles.marginBottom,
            paddingTop: styles.paddingTop,
            paddingBottom: styles.paddingBottom
        };
        const styleKeys = Object.keys(heightVal);

        styleKeys.forEach((key) => {
            if (parseFloat(heightVal[key]) === 0) {
                delete heightVal[key];
            }
        });

        if (styleKeys.length === 0) {
            return;
        }

        if (isSlideDown) {
            styleKeys.forEach((key) => {
                targetStyle[key] = 0;
            });
        } else {
            styleKeys.forEach((key) => {
                targetStyle[key] = heightVal[key];
                heightVal[key] = 0;
            });
        }
        await target.animate(heightVal, {
            duration: options.duration,
            easing: options.easing
        }).finished;

        targetClassList.remove(busyClass);

        targetStyle.overflow = '';
        styleKeys.forEach((key) => {
            targetStyle[key] = '';
        });

        if (isSlideUp) {
            targetStyle.display = textNone;
        }

        const callBack = options.callBack;
        if (typeof callBack === 'function') {
            callBack();
        }
    };

    const closeVisitorPanel = () => {
        const slideToggleElm = document.querySelector('.session-view-container');
        slideAnime({
            target: slideToggleElm,
            animeType: 'slideToggle'
        }).then(() => {
            setLoading(true);
        });
    };

    useEffect(() => {
        if (connectionHub && selectedClient.connectionHubId) {
            // console.log('send status session-view client', connectionHubId);
            connectionHub.send(
                'SendSessionViewRequest',
                connectionHubId,
                'session-view',
                true,
                window.location.href,
                selectedClient.connectionHubId
            );

            connectionHub.on('ReceivedSessionViewResponse', ({ clientId, signalData }) => {
                console.log('received session view response', clientId);
                setSenderId(clientId);
                setSignalData(signalData);
            });
        }

        return () => {
            setLoading(true);
        };
    }, [connectionHub, selectedClient]);

    useEffect(() => {
        if (statusShareScreenClient) {
            let iframeElement;
            let interval = setInterval(() => {
                if (document.getElementById('playerId')) {
                    iframeElement = document.getElementById('playerId').getElementsByTagName('iframe')[0];
                    if (iframeElement)
                        // getElementIframe.style.border = '3px solid #20643b';
                        clearInterval(interval);
                }
            }, 500);
        }
    }, [statusShareScreenClient]);

    useEffect(() => {
        if (connectionHub) {
            // connectionHub.on('StatusrrwebClient', (result) => {
            //     if (
            //         sessionStorage.getItem('userAcceptCallID') &&
            //         sessionStorage.getItem('userAcceptCallIdInIFrame') &&
            //         sessionStorage.getItem('userAcceptCallID') == sessionStorage.getItem('userAcceptCallIdInIFrame')
            //     ) {
            //         setStatusShareScreenClient(result.isIFrame);
            //     }
            //     if (result.callControl == 'endcall' || result.callControl != 'startcall') {
            //         setStatusShareScreenClient(false);
            //         player = null;
            //         recordings = [];
            //     }
            // });

            // connectionHub.on('ReceiveWebSignalData', ({ receiverId, senderId, signalData }) => {
            //     if (receiverId === connectionHubId) {
            //         setSenderId(senderId);
            //         setSignalData(signalData);
            //     }
            // });
        }

        return () => {
            connectionHub?.off('StatusrrwebClient');
            connectionHub?.off('ReceiveWebSignalData');
        };
    }, [connectionHub]);

    useEffect(() => {
        if (senderId && signalData) {
            getIceServers().then((iceServers) => {
                const peer = new window.SimplePeer({
                    initiator: false,
                    trickle: false,
                    config: {
                        iceServers: iceServers
                    }
                });
                peerWeb.current = peer;
                peerWeb.current.signal(signalData);

                peerWeb.current.on('signal', (data) => {
                    if (connectionHub) {
                        console.log('>>> peerWeb on signal', data);
                        setConnectingMessage('on signal');
                        connectionHub.send('WebSignalData', senderId, connectionHubId, JSON.stringify(data));
                    }
                });

                peerWeb.current.on('connect', () => {
                    setConnectingMessage('on connect');
                    setLoading(false);
                });

                peerWeb.current.on('data', (data) => {
                    setConnectingMessage('on data');

                    const dataString = Buffer.from(data).toString('utf-8');
                    let peerData;

                    if (dataString.includes('isChunkDone')) {
                        peerData = JSON.parse(chunkBufferString);
                        isChunk = false;
                        chunkBufferString = '';
                    } else if (dataString.includes('isChunk')) {
                        isChunk = true;
                    } else if (isChunk) {
                        chunkBufferString = chunkBufferString.concat(dataString);
                    } else {
                        peerData = JSON.parse(dataString);
                    }

                    if (!isChunk) {
                        if (peerData.isIFrame) {
                            setScreenWidth(peerData.screenWidth);
                            setScreenHeight(peerData.screenHeight);
                            setScaleReplayer(peerData.innerWidth);
                            setReplayerScale(peerData.screenWidth, peerData.screenHeight);

                            // console.log('check status player 3', sessionStorage.getItem('userAcceptCallID'), peerData.connectionId);
                            const event = peerData.eventClient;
                            // console.log('event client', event);

                            const setReplayerLoading = () => {
                                //  const divEle =
                                //      document.getElementsByClassName('replayerLoading')[0];
                                //  divEle.style.width =
                                //      document
                                //          .getElementsByClassName('replayer-wrapper')[0]
                                //          .getBoundingClientRect().width + 'px';
                                //  divEle.style.height =
                                //      document
                                //          .getElementsByClassName('replayer-wrapper')[0]
                                //          .getBoundingClientRect().height + 'px';
                            };
                            const removeReplayerLoadingStuck = (event) => {
                                if (event.sender === 'agent' && isIgnoredOnRmoteControl(event)) {
                                    setIsReplayerLoading(false);
                                }
                            };

                            (async () => {
                                // console.log(
                                //     'replayer event out conditional',
                                //     player,
                                //     recordings.length,
                                // );
                                if (!player && recordings.length >= 1) {
                                    if (document.querySelector('.replayer-wrapper') != null)
                                        document.querySelector('.replayer-wrapper').remove();

                                    player = new Replayer(recordings, {
                                        liveMode: true,
                                        root: document.getElementById('playerId'),
                                        insertStyleRules: [
                                            // '#agent-cursor { display: none !important }',
                                        ],
                                        mouseTail: false
                                        // unpackFn: unpack,
                                    });

                                    player.on('resize', ({ width, height }) => {
                                        if (width < height) {
                                            document.querySelector('.replayer-wrapper').classList.add('portrait');
                                        }
                                    });

                                    connectionHub.send(
                                        'SendStatusPlayer',
                                        sessionStorage.getItem('userAcceptCallID'),
                                        true
                                    );

                                    player.startLive(event.timestamp);
                                    // player.enableInteract();

                                    setTimeout(() => {
                                        const onLoad = () => {
                                            setTimeout(() => {
                                                onMirror(player.iframe, (payload) => {
                                                    sendMirrorAction(payload);
                                                });
                                            }, 50);
                                        };

                                        player.iframe.addEventListener('load', onLoad);

                                        onMirror(player.iframe, (payload) => {
                                            sendMirrorAction(payload);
                                        });

                                        setReplayerLoading();
                                    }, 1200);

                                    setIframeWidth(peerData.innerWidth);
                                } else if (recordings.length < 2) {
                                    await recordings.push(event);
                                }

                                if (player) {
                                    if (!isIgnoredOnRmoteControl(event) && !isDuplicatedEvent(event, recentEvent)) {
                                        if (!handleLoadPageEvent(event, player.iframe)) {
                                            recentEvent = event;
                                            player.addEvent(event);
                                        }
                                    }
                                    removeReplayerLoadingStuck(event);
                                }
                            })();
                        }
                    }
                });

                peerWeb.current.on('close', () => {
                    // console.log('close peer for replayer', peerWeb.current);
                });
                peerWeb.current.on('error', (err) => {
                    console.error('error', err, 'time: ' + moment().format('HH:mm:ss'));
                });
            });
        }
    }, [signalData]);

    const getIceServers = async () => {
        var iceserverdata = await utilService.getStaticIceServer();
        const iceServers = [{ urls: iceserverdata.data.stunURI }];

        iceserverdata.data.turnServers.forEach((item) => {
            // console.log('check turnServers sales agent', item, moment().format('HH:mm:ss'));
            iceServers.push({
                urls: item.uris,
                username: item.username,
                credential: item.password
            });
        });

        return iceServers;
    };

    const sendMirrorAction = async () => {
        if (peerWeb && peerWeb.current) {
            try {
                // const payloadBytes = Buffer.from(JSON.stringify(payload), 'utf-8');
                // peerWeb.current.send(payloadBytes);
            } catch (error) {
                // console.log('Mirror action error: ', error);
            }
        }
    };

    const setScaleReplayer = (innerWidth) => {
        return;
        if (innerWidth >= 3840) {
            setScaleWidth('scale(0.4)');
        } else if (innerWidth < 3840 && innerWidth >= 2000) {
            setScaleWidth('scale(0.5)');
        } else if (innerWidth < 2000 && innerWidth >= 1900) {
            setScaleWidth('scale(0.9)');
        } else if (innerWidth < 1900 && innerWidth >= 1800) {
            setScaleWidth('scale(0.9)');
        } else if (innerWidth < 1800 && innerWidth >= 1700) {
            setScaleWidth('scale(0.95)');
        } else if (innerWidth < 1700 && innerWidth >= 1600) {
            setScaleWidth('scale(1)');
        } else if (innerWidth < 1600 && innerWidth >= 1500) {
            setScaleWidth('scale(1.08)');
        } else if (innerWidth < 1500 && innerWidth >= 1400) {
            setScaleWidth('scale(1.15)');
        } else if (innerWidth < 1400 && innerWidth >= 1300) {
            setScaleWidth('scale(1.22)');
        } else if (innerWidth < 1300 && innerWidth >= 1200) {
            setScaleWidth('scale(1.32)');
        } else if (innerWidth < 1200 && innerWidth >= 1100) {
            setScaleWidth('scale(1.42)');
        } else if (innerWidth < 1100 && innerWidth >= 1000) {
            setScaleWidth('scale(1.52)');
        } else if (innerWidth < 1000 && innerWidth >= 900) {
            setScaleWidth('scale(1.7)');
        } else if (innerWidth < 900 && innerWidth >= 800) {
            setScaleWidth('scale(1.8)');
        } else if (innerWidth < 800 && innerWidth >= 700) {
            setScaleWidth('scale(1.9)');
        } else if (innerWidth < 700 && innerWidth >= 600) {
            setScaleWidth('scale(2)');
        } else if (innerWidth < 600 && innerWidth >= 500) {
            setScaleWidth('scale(2.1)');
        } else if (innerWidth < 500 && innerWidth >= 400) {
            setScaleWidth('scale(2.2)');
        } else if (innerWidth < 400 && innerWidth >= 300) {
            setScaleWidth('scale(2.3)');
        } else if (innerWidth < 300 && innerWidth >= 200) {
            setScaleWidth('scale(2.4)');
        }
    };

    const setReplayerScale = (width, height) => {
        const replayerWrapper = document.querySelector('.replayer-wrapper');
        console.log('replayerWrapper', replayerWrapper);
        if (replayerWrapper) {
            replayerWrapper.style.width = `${width}px`;
            replayerWrapper.style.height = `${height}px`;
            replayerWrapper.style.transform = `scale(0.955263)`;
            replayerWrapper.style.marginTop = `-16.6868px`;
            replayerWrapper.style.marginLeft = `-25.5px`;

            // height: 746px;
            // width: 1140px;
            // transform: scale(0.955263);
            // margin-top: -16.6868px;
            // margin-left: -25.5px;
        }
    };

    const setIframeWidth = () => {
        if (document.getElementsByTagName('iframe').length > 0) {
            // const sharingIframe = document.getElementsByTagName('iframe')[0];
        }
    };

    return (
        <>
            <div className="session-view-container">
                <div className="rrweb-wrapper">
                    <div className="rrweb">
                        <PlayerToolbar>
                            <ButtonCall className="btn">
                                <Icon color={`#6259CE`} size={22} icon={`video`} style={{ marginTop: -1 }} />
                            </ButtonCall>
                            <ButtonChat className="btn">
                                <Icon color={`#28C66F`} size={18} icon={`chat`} />
                            </ButtonChat>
                            <ButtonClose className="btn" onClick={() => closeVisitorPanel()}>
                                <TbScreenShareOff size={20} color={'#ec5453'} style={{ marginTop: -1 }} />
                            </ButtonClose>
                        </PlayerToolbar>

                        {loading ? (
                            <Loading>
                                <MoonLoader size={60} color={'#9c9c9c'} loading={loading} />
                                <h4>{connectingMessage}</h4>
                            </Loading>
                        ) : (
                            <></>
                        )}

                        <div
                            id="playerId"
                            className="rrwebPlayer --uninteractive --hide-mouse"
                            style={{ width: screenWidth, height: screenHeight }}></div>

                        {/* <div
                            className="widgetRrweb"
                            style={{ width: screenWidth, height: screenHeight }}></div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SessionView;
