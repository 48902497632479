import moment from 'moment-timezone';
import { useState, useEffect, useRef } from 'react';
import './style.scss';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Icon } from 'components';
import React from 'react';

interface IdatePicker {
    isUpdate: boolean;
    startTime: string;
    endTime: string;
    dateActive: string;
    startLabel? : string;
    endLabel? : string;
}

const DatePickerOptionFully = (prop: IdatePicker) => {
    const [endDate, setEndDate] = useState<any>(new Date());
    const [startDate, setStartDate] = useState<any>(new Date(prop.dateActive.split('T')[0]));
    const [sTime, setSTime] = React.useState<any>(moment().add(5, 'minutes'));
    const [eTime, setETime] = React.useState<any>(moment().add(10, 'minutes'));

    const inputBeginRef = useRef();
    const inputEndRef = useRef();

    const triggerCalendar = (inputRef) => {
        inputRef.current?.handleFocus()
    };

    useEffect(() => {
        if (prop.isUpdate) {
            setSTime(moment(prop.startTime));
            setETime(moment(prop.endTime));
            setStartDate(new Date(prop.startTime.split('T')[0]));
            setEndDate(new Date(prop.endTime.split('T')[0]));
        }
    }, [prop]);

    // useEffect(() => {
    //     let st = moment(sTime).add(5, 'minutes');
    //     setETime(st);
    // }, [sTime]);

    return (
        <>
            <div className="container-date-picker-fully">
                <div className='d-flex'>
                    <label>{prop.startLabel}</label> 
                    <div className="container-date-picker-fully-option first-section">
                        <div className="contianer-time-picker" >
                            <TimePicker
                                showSecond={false}
                                value={sTime}
                                defaultValue={sTime}
                                onChange={setSTime}
                                inputReadOnly={false}
                            />
                        </div>
                        <div className="contianer-date-picker">
                            <DatePicker
                                selected={startDate}
                                onChange={(date: any) => setStartDate(date)}
                                dateFormat="dd-MM-yyyy"
                                className={`input-date`}
                                calendarStartDay={1}
                                ref={inputBeginRef}
                            />
                            <div className="icon-calendar" onClick={() => triggerCalendar(inputBeginRef)}>
                                <Icon icon="calendar-icon" size={20} color={`#FFF`} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex'>
                    <label>{prop.endLabel}</label>
                    <div className="container-date-picker-fully-option second-section">
                        <div className="contianer-time-picker">
                            <TimePicker
                                showSecond={false}
                                defaultValue={eTime}
                                value={eTime}
                                onChange={setETime}
                                inputReadOnly={false}
                            />
                        </div>
                        <div className="contianer-date-picker">
                            <DatePicker
                                selected={endDate}
                                onChange={(date: any) => setEndDate(date)}
                                dateFormat="dd-MM-yyyy"
                                className={`input-date`}
                                calendarStartDay={1}
                                ref={inputEndRef}
                            />
                            <div className="icon-calendar" onClick={() => triggerCalendar(inputEndRef)}>
                                <Icon icon="calendar-icon" size={20} color={`#FFF`} />
                            </div>
                        </div>
                    </div>
                </div>
                

                {/* <div className="container-date-picker-fully-option-endtime">
                    <div className="end-time">
                        <TimePicker
                            className="end-time"
                            showSecond={false}
                            defaultValue={eTime}
                            value={eTime}
                            onChange={setETime}
                            inputReadOnly={false}
                        />
                    </div>
                    <div className="end-date">
                        <DatePicker
                            selected={startDate}
                            onChange={(date: any) => setStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={`input-end-date`}
                            calendarStartDay={1}
                        />
                        <div className="icon-calendar" onClick={openCalendarEndDate}>
                            <Icon icon="calendar-icon" size={20} color={`#FFF`} />
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};
export default DatePickerOptionFully;
