import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { DropdownOnchange, InputTextArea } from 'components';
import DatePickerOption from 'components/date-picker';
import InputBox from 'components/input-box';
import { useEffect, useState } from 'react';
import './style.scss';
import { useServices } from 'services';
import { IerrorMessage } from 'interfaces';
import { useNavigate } from 'react-router-dom';

interface IModalSerice {
    showButtonDelete?: string;
    deleteHandler?: (e: any) => void;
    onClose?: (e: any) => void;
    startTime?: string;
    endTime?: string;
    selectedsalesAgentId?: any;
    checkUpdate?: boolean;
    selectedDate?: any;
    handleOpenSnackbar?: (isSuccess: any, msg: any) => void;
    toggleBackdrop?: (e: any) => void;
    title?: string;
    description?: string;
    titleHeader?: string;
    clientName?: string;
    clientEmail?: string;
    clientPhoneNumber: string;
    errorMessage?: Array<IerrorMessage>;
    isValidateFormEvent?: boolean;
    userRoleLoggedIn : number;
}

const validateSalesAgent = (selectedSaleAgentId: any, saleAgentId: any) => {
    if (selectedSaleAgentId) {
        if (saleAgentId === selectedSaleAgentId) {
            return true;
        }
        return false;
    } else {
        if (saleAgentId === 6) {
            return true;
        }
        return false;
    }
};
const ModalServiceCallEntryForm = (prop: IModalSerice) => {
    const { api } = useServices();
    const [salesAgentList, setSalesAgentList] = useState<any>([]);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [desc, setDesc] = useState<string>(prop.description.replaceAll('<br/>', '\n'));
    const [nameServiceCallErrorMessage, setNameServiceCallErrorMessage] = useState<string>(null);
    const [descriptionServiceCallErrorMessage, setDescriptionServiceCallErrorMessage] =
        useState<string>(null);
    const [startDateErrorMessage, setStartDateErrorMessage] = useState<string>(null);
    const [startTimeErrorMessage, setStartTimeErrorMessage] = useState<string>(null);
    const [endTimeErrorMessage, setEndTimeErrorMessage] = useState<string>(null);
    const [salesAgentErrorMessage, setSalesAgentErrorMessage] = useState<string>(null);
    const [clientNameErrorMessage, setClientNameErrorMessage] = useState<string>(null);
    const [clientEmailErrorMessage, setClientEmailErrorMessage] = useState<string>(null);
    const [clientPhoneErrorMessage, setClientPhoneErrorMessage] = useState<string>(null);
    const navigate = useNavigate();

    const clearStateAll = () => {
        setNameServiceCallErrorMessage(null);
        setDescriptionServiceCallErrorMessage(null);
        setStartDateErrorMessage(null);
        setStartTimeErrorMessage(null);
        setEndTimeErrorMessage(null);
        setSalesAgentErrorMessage(null);
        setClientNameErrorMessage(null);
        setClientEmailErrorMessage(null);
        setClientPhoneErrorMessage(null);
    }

    useEffect(() => {
        console.log("userRoleLoggedIn", prop.userRoleLoggedIn);
      (async () => {
            let result: any = await api.serviceCall.getSalesAgentList();
            let saleAgents: any = result.data;
            let getSaleAgents = [];
            saleAgents.forEach((item: any) => {
                if (item.role === 2) {
                    let saleAgent = {
                        id: item.userId,
                        text: item.userFullName,
                        value: item.userId,
                        defaultValue: validateSalesAgent(
                            parseInt(prop.selectedsalesAgentId),
                            item.userId,
                        ),
                    };
                    getSaleAgents.push(saleAgent);
                }
            });

            setSalesAgentList(getSaleAgents);
            setIsUpdate(prop.checkUpdate);
        })();

    
        clearStateAll();

        if (prop.errorMessage && prop.isValidateFormEvent) {
            prop.errorMessage.forEach((error: IerrorMessage) => {
                if (error.propertyName === 'Name') {
                    setNameServiceCallErrorMessage(error.errorMessage);
                } else if (error.propertyName === 'Description') {
                    setDescriptionServiceCallErrorMessage(error.errorMessage);
                } else if (error.propertyName === 'StartDate') {
                    setStartDateErrorMessage(error.errorMessage);
                } else if (error.propertyName === 'StartTime') {
                    setStartTimeErrorMessage(error.errorMessage);
                } else if (error.propertyName === 'EndTime') {
                    setEndTimeErrorMessage(error.errorMessage);
                } else if (error.propertyName === 'SaleAgentId') {
                    setSalesAgentErrorMessage(error.errorMessage);
                } else if (error.propertyName === 'ClientName') {
                    setClientNameErrorMessage(error.errorMessage);
                } else if (error.propertyName === 'ClientEmail') {
                    setClientEmailErrorMessage(error.errorMessage);
                } else if (error.propertyName === 'ClientPhoneNumber') {
                    setClientPhoneErrorMessage(error.errorMessage);
                }
            });
        }
    }, [prop]);

    return (
        <>
            <div className="calendar-event">
                <div className="calendar-title-event modal-header">
                    <div className="calendar-title-text-event">{prop.titleHeader}</div>
                    <div className="calendar-close-event" onClick={e => prop.onClose(e)}>
                        {' '}
                        <FontAwesomeIcon icon={faX} size={`1x`} />
                    </div>
                </div>
                <div className="calendar-content-event modal-body">
                    {/************************************* */}
                    <div className="calendar-container-event">
                        <div className="calendar-left-event">
                            <label htmlFor="title">Title :</label>
                        </div>
                        <div className="calendar-right-event">
                            <InputBox
                                typeInput={`text`}
                                className={``}
                                placeholder={`Title*`}
                                defaultValue={prop.title}
                                fucntionCustom={() => {}}
                                isRequired={true}
                                isReadOnly={false}
                            />
                            {nameServiceCallErrorMessage ? (
                                <span className="error-message">
                                    {nameServiceCallErrorMessage.replace('Name', 'Title')}
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="calendar-container-event">
                        <div className="calendar-left-event textarea">
                            <label htmlFor="description">Description :</label>
                        </div>
                        <div className="calendar-right-event textarea">
                            <InputTextArea
                                className={``}
                                placeholder={`Description`}
                                defaultValue={desc}
                                fucntionCustom={() => {}}
                                isRequired={false}
                                isReadOnly={false}
                            ></InputTextArea>
                        </div>
                    </div>
                    <div className="calendar-container-event">
                        <div className="calendar-left-event">
                            <label htmlFor="timeDate">Time & Date :</label>
                        </div>
                        <div className="calendar-right-event">
                            <DatePickerOption
                                isUpdate={prop.checkUpdate}
                                startTime={prop.startTime}
                                endTime={prop.endTime}
                                dateActive = {prop.endTime}
                            />

                            {startDateErrorMessage ? (
                                <div className="error-message">{startDateErrorMessage}</div>
                            ) : (
                                ''
                            )}
                            {startTimeErrorMessage ? (
                                <div className="error-message">{startTimeErrorMessage}</div>
                            ) : (
                                ''
                            )}
                            {endTimeErrorMessage ? (
                                <div className="error-message">{endTimeErrorMessage}</div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="calendar-container-event">
                        <div className="calendar-left-event">
                            <label htmlFor="salesAgent">Sales Agent :</label>
                        </div>
                        <div className="calendar-right-event">
                            <DropdownOnchange
                                data={salesAgentList}
                                border={`1px solid #C5C5C5`} //Property
                                optionOnChange={() => setSalesAgentList(salesAgentList)}
                                isReadOnly={prop.userRoleLoggedIn < 2 ? false : true}
                            />

                            {salesAgentErrorMessage ? (
                                <span className="error-message">{salesAgentErrorMessage}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="calendar-container-event">
                        <div className="calendar-left-event">
                            <label htmlFor="clientName">Client Name :</label>
                        </div>
                        <div className="calendar-right-event">
                            <InputBox
                                typeInput={`text`}
                                className={``}
                                placeholder={`Client Name*`}
                                defaultValue={prop.clientName}
                                fucntionCustom={() => {}}
                                isRequired={true}
                                isReadOnly={prop.checkUpdate}
                            />
                            {clientNameErrorMessage ? (
                                <span className="error-message">{clientNameErrorMessage}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="calendar-container-event">
                        <div className="calendar-left-event">
                            <label htmlFor="clientEmail">Client Email :</label>
                        </div>
                        <div className="calendar-right-event">
                            <InputBox
                                typeInput={`text`}
                                className={``}
                                placeholder={`Client Email*`}
                                defaultValue={prop.clientEmail}
                                fucntionCustom={() => {}}
                                isRequired={true}
                                isReadOnly={prop.checkUpdate}
                            />
                            {clientEmailErrorMessage ? (
                                <span className="error-message">{clientEmailErrorMessage}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="calendar-container-event">
                        <div className="calendar-left-event">
                            <label htmlFor="clientNumber">Client Number :</label>
                        </div>
                        <div className="calendar-right-event">
                            <InputBox
                                typeInput={`text`}
                                className={``}
                                placeholder={`Client Number*`}
                                defaultValue={prop.clientPhoneNumber}
                                fucntionCustom={() => {}}
                                isRequired={true}
                                isReadOnly={false}
                            />
                            {clientPhoneErrorMessage ? (
                                <span className="error-message">{clientPhoneErrorMessage}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="calendar-container-event">
                        <div className="calendar-left-event">
                            <div className="cancel-event" onClick={e => prop.onClose(e)}>
                                <span>
                                    {' '}
                                    <FontAwesomeIcon icon={faX} size={`1x`} />
                                </span>
                                <span>Cancel</span>
                            </div>
                        </div>
                        <div className="calendar-right-event button-action-event">
                            <button type="submit" className="btn save" onClick={e => {}}>
                                <span>
                                    <FontAwesomeIcon icon={faCheck} />
                                </span>
                                {` `}
                                <span>Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalServiceCallEntryForm;
