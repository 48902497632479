import React from "react";
import { BounceLoader, BarLoader } from "react-spinners";
import "./style.scss";
import { COLORS } from "utils/constants";

function WaitingProcessLoadData(props) {

    const { playerWidth, playerHeight } = props;

    return (
        <>
            <div className={`rrweb-loading-container`} style={{width: playerWidth, height: playerHeight}}>
                <div className="rrweb-loading-wrapper">
                    
                    <h3>Connecting</h3>

                    <BarLoader
                        color={COLORS.primaryLight}
                        loading={true}
                        height={4}
                        width={100}
                        speedMultiplier={1}
                    />
                  
                </div>
            </div>

        </>
    );
}

export default WaitingProcessLoadData;


