import axios from "axios";

class apiService {
  forgotPassword(email) {
    return axios
      .post(`/api/v1/user/command/forgot-password-restore`, { email: email })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  resetPassword(password, email, code) {
    return axios
      .post(`/api/v1/user/command/restore-password-by-code`, {
        code: code,
        email: email,
        newPassword: password,
      })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  confirmEmail(email, code) {
    return axios
      .patch(`/api/v1/user/command/confirm-email`, { Email: email, Code: code })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  signUp(companyName, username, firstname, lastname, password) {
    return axios
      .post(`/api/v1/tenant/command/sign-up`, {
        CompanyName: companyName,
        Username: username,
        Firstname: firstname,
        Lastname: lastname,
        Password: password,
      })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  changePassword = (oldPassword, password, confirmPassword) => {
    return apiConfigService.patchCallAPIWrapper(
      `/api/v1/user/command/change-password`,
      {
        OldPassword: oldPassword,
        NewPassword: password,
        ConfirmPassword: confirmPassword,
      }
    );
  };

  getTenants = (page, rowsPerPage, order, orderBy) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/account/query/accounts`,
      {
        params: {
          Page: page,
          SortDirection: order,
          SortColumn: orderBy,
          PageSize: rowsPerPage,
        },
      }
    );
  };

  getTenant = (id) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/account/query/account/${id}`
    );
  };

  getUsers = (page, rowsPerPage, order, orderBy) => {
    return apiConfigService.getCallAPIWrapper(`/api/v1/user/query/users`, {
      params: {
        Page: page,
        SortDirection: order,
        SortColumn: orderBy,
        PageSize: rowsPerPage,
      },
    });
  };

  getUser = (id) => {
    return apiConfigService.getCallAPIWrapper(`/api/v1/user/query/user/${id}`);
  };

  getCategories = (page, rowsPerPage, order, orderBy) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/category/query/categories`,
      {
        params: {
          Page: page,
          SortDirection: order,
          SortColumn: orderBy,
          PageSize: rowsPerPage,
        },
      }
    );
  };

  getCategoriesForTenant = (id) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/category/query/categories/${id}`
    );
  };

  getCategory = (id) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/category/query/category/${id}`
    );
  };

  getProducts = (page, rowsPerPage, order, orderBy) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/product/query/products`,
      {
        params: {
          Page: page,
          SortDirection: order,
          SortColumn: orderBy,
          PageSize: rowsPerPage,
        },
      }
    );
  };

  getProduct = (id) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/product/query/product/${id}`
    );
  };

  // getDashboard = () => {
  //   return apiConfigService.getCallAPIWrapper(`/api/v1/dashboard/query/dashboard`);
  // }

  createConnectionRequest = (connectionId) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/signalR/query/createConnectionRequest`,
      { params: { connectionId: connectionId } }
    );
  };

  createConnectionAnswer = (fromId, toId) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/signalR/query/createConnectionAnswer`,
      { params: { fromId: fromId, toId: toId } }
    );
  };

  sendControlMessage = (command, message, toId) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/signalR/query/sendControlMessage`,
      { params: { command: command, message: message, user: toId } }
    );
  };

  sendControlMessage2 = (command, message, toId) => {
    return apiConfigService.postCallAPIWrapper(
      `/api/v1/signalR/query/sendControlMessage2`,
      { command: command, message: message, user: toId }
    );
  };

  sendCallUser = (command, message, toId) => {
    return apiConfigService.postCallAPIWrapper(
      `/api/v1/signalR/query/sendCallUser`,
      { command: command, message: message, user: toId }
    );
  };

  sendAcceptCall = (command, message, toId) => {
    return apiConfigService.postCallAPIWrapper(
      `/api/v1/signalR/query/sendAcceptCall`,
      { command: command, message: message, user: toId }
    );
  };

  getMe = () => {
    // return apiConfigService.getCallAPIWrapper(`/api/v1/user/me`);
  };

  getUserProfile = () => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/user/query/user-profile`
    );
  };

  postUserProfile = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/user/command/update-profile",
      formData,
      params
    );
  };

  createUser = async (formData, params) => {
    return await apiConfigService.postCallAPIWrapper(
      "/api/v1/user/command/create",
      formData,
      params
    );
  };

  updateUser = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/user/command/update",
      formData,
      params
    );
  };

  deleteUser = (id) => {
    return apiConfigService.deleteCallAPIWrapper(
      "/api/v1/user/command/delete/" + id
    );
  };

  setMessageAsRead = (messages) => {
    return apiConfigService.patchCallAPIWrapper(
      `/api/v1/message/command/set-as-read`,
      { messageIds: messages }
    );
  };

  createAccount = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/account/command/create",
      formData,
      params
    );
  };

  updateAccount = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/account/command/update",
      formData,
      params
    );
  };

  deleteAccount = (id) => {
    return apiConfigService.deleteCallAPIWrapper(
      "/api/v1/account/command/delete/" + id
    );
  };

  createCategory = (params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/category/command/create",
      params
    );
  };

  updateCategory = (params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/category/command/update",
      params
    );
  };

  deleteCategory = (id) => {
    return apiConfigService.deleteCallAPIWrapper(
      "/api/v1/category/command/delete/" + id
    );
  };

  createProduct = (params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/product/command/create",
      params
    );
  };

  updateProduct = (params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/product/command/update",
      params
    );
  };

  deleteProduct = (id) => {
    return apiConfigService.deleteCallAPIWrapper(
      "/api/v1/product/command/delete/" + id
    );
  };

  signalrMessage = (message) => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/signalR/query/sendMessage/" + message
    );
  };

  getDashboard = () => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/callAudit/query/dashboard"
    );
  };

  getServiceCallListThisWeek = (page, rowsPerPage, order, orderBy) => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/callAudit/query/serviceCallListThisWeek",
      {
        params: {
          Page: page,
          SortDirection: order,
          SortColumn: orderBy,
          PageSize: rowsPerPage,
        },
      }
    );
  };

  removeGoogle = (params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/authentication/remove-login-google",
      params
    );
  };

  addLoginGoogle = (params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/authentication/add-login-google",
      params
    );
  };

  createServiceCall = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/service-call/command/book-service-call",
      formData,
      params
    );
  };

  getServiceCallsCalendar = (startTime, endTime, salesAgentId) => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/service-call/query/service-calls-calendar",
      {
        params: {
          StartDate: startTime,
          EndDate: endTime,
          SaleAgentId: salesAgentId,
        },
      }
    );
  };

  getSalesAgentList = () => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/user/query/sale-agents-dropdown-items"
    );
  };

  updateServiceCall = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/service-call/command/update-service-call",
      formData,
      params
    );
  };

  cancelServiceCall = (formData) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/service-call/command/cancel-service-call/",
      formData
    );
  };

  getCallingTimeout = () => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/setting/get-call-settings"
    );
  };

  getCallSetting = () => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/setting/query/get-call-settings"
    );
  };

  // updateReconnectCall = (formData, params) => {
  //   return apiConfigService.postCallAPIWrapper(
  //     "/api/v1/setting/command/update-reconnect-call",
  //     formData,
  //     params
  //   );
  // };

  UpdateClosePortal = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/setting/command/update-close-portal",
      formData,
      params
    );
  };

  getAllCallRecords = (
    page,
    rowsPerPage,
    order,
    orderBy,
    saleAgentId,
    startDate,
    endDate,
    statusRecord,
    statusConvertText,
    statusSentimentAnalysis
  ) => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/callRecord/query/allcallRecords",
      {
        params: {
          Page: page,
          SortDirection: order,
          SortColumn: orderBy,
          PageSize: rowsPerPage,
          SaleAgentId: saleAgentId,
          StartDate: startDate,
          EndDate: endDate,
          StatusRecord: statusRecord,
          StatusConvertText: statusConvertText,
          StatusSentimentAnalysis: statusSentimentAnalysis,
        },
      }
    );
  };

  deleteCallRecord = (id) => {
    return apiConfigService.deleteCallAPIWrapper(
      "/api/v1/callRecord/command/delete/" + id
    );
  };

  getCallRecord = (id) => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/callRecord/query/callRecord/" + id
    );
  };

  actionQueues = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/callRecord/command/actionQueues",
      formData,
      params
    );
  };

  getDownloadCallRecord = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/callRecord/command/downloadCallRecord",
      formData,
      params
    );
  };

  downloadSoundFile = (callQueuesId, audioFileId) => {
    return apiConfigService.getCallAPIWrapper(
      "/api/v1/callRecord/query/downloadCallRecord",
      {
        params: {
          callQueuesId: callQueuesId,
          audioFileId: audioFileId,
        },
        responseType: "blob",
      }
    );
  };

  // generateCallInvitation = (formData, params) => {
  //   return apiConfigService.postCallAPIWrapper(
  //     "/api/v1/invitation-call/command/generate-call-invitation",
  //     formData,
  //     params
  //   );
  // };

  // sendInvitationUsingEmail = (formData, params) => {
  //   return apiConfigService.postCallAPIWrapper(
  //     "/api/v1/invitation-call/command/invitation-using-email",
  //     formData,
  //     params
  //   );
  // };

  // sendInvitationUsingSMS = (formData, params) => {
  //   return apiConfigService.postCallAPIWrapper(
  //     "/api/v1/invitation-call/command/invitation-using-sms",
  //     formData,
  //     params
  //   );
  // };

  getTriggers = (page, rowsPerPage, order, orderBy) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/conditionalMessage/query/conditionalMessages`,
      {
        params: {
          Page: page,
          SortDirection: order,
          SortColumn: orderBy,
          PageSize: rowsPerPage,
        },
      }
    );
  };

  createTriggers = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/conditionalMessage/command/createTrigger",
      formData,
      params
    );
  };

  getTrigger = (id) => {
    return apiConfigService.getCallAPIWrapper(
      `/api/v1/conditionalMessage/query/conditionalMessage/${id}`
    );
  };

  updateTrigger = (formData, params) => {
    return apiConfigService.postCallAPIWrapper(
      "/api/v1/conditionalMessage/command/updateTrigger",
      formData,
      params
    );
  };

  deleteTrigger = (id) => {
    return apiConfigService.deleteCallAPIWrapper(
      "/api/v1/conditionalMessage/command/deleteTrigger/" + id
    );
  };
}
export default new apiService();

export const API_URL = process.env.NODE_ENV !== 'production'?  process.env.REACT_APP_API_URL: '';
