import { IclientSentimentAnalysis, IlistKeyPhrases } from 'interfaces';
import './style.scss';

const KeyPhiaseClient = (props: IclientSentimentAnalysis) => {
    let user = '';
    let keyPhrasesText = '';
    if (props.clientSentimentAnalysis) {
        let result: IlistKeyPhrases = JSON.parse(props.clientSentimentAnalysis);
        user = result.UserType == 0 ? 'Client' : 'Sale agent';
        keyPhrasesText = result.KeyPhrases.length > 0 ? result.KeyPhrases.toString() : '-';
    }

    return (
        <>
            <div className="card key-phrases">
                <div className="card-body body-content">
                    <h4 className="mb-4">Key Phrases Form Conversation {user}</h4>
                    <p className="paragraph-content">{keyPhrasesText}</p>
                </div>
            </div>
        </>
    );
};

export default KeyPhiaseClient;
