import { IcardInputText } from 'interfaces/Icard-input-text';
import { useState, useEffect } from 'react';

import './style.scss';
import Icon from 'components/icon-moon';

function CardInputText(prop: IcardInputText) {
    const [text, setText] = useState<string>();
    const [colorIconCopy] = useState('#6259ce');

    useEffect(() => {
        if (prop.onChange) prop.onChange(text);
    }, [text]);

    useEffect(() => {
        setText(prop.text);
    }, [prop.text]);

    return (
        <>
            <div className="card container-card-custom card-input-text">
                <div className="card-body">
                    <div className="title">{prop.title}</div>
                    <div className="sub-title">{prop.subTitle}</div>
                    <div
                        className={`col-lg-${prop.colLg} col-md-${prop.colMd} col-sm-${prop.colSm}  mb-3 container-input-class`}>
                        <input
                            id={prop.setElementId}
                            type={`${prop.typeInput}`}
                            onChange={(e) => setText(e.target.value)}
                            className={`input-class`}
                            defaultValue={text}
                            readOnly={prop.isReadOnly}
                            style={{ color: prop.isReadOnly ? '#888888' : 'black' }}
                            placeholder={prop.placeholderText}
                        />
                        {prop.subTitle.toLowerCase().search('copy') === 0 ? (
                            <span onClick={prop.functionClick} style={{ cursor: 'pointer' }}>
                                <Icon color={colorIconCopy} size={28} icon="copy" className="iconCopy" />
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardInputText;
