import { useEffect, useState } from 'react';
import './style.scss';

interface Irate {
    rate: number;
}

const Rates = (prop: Irate) => {
    const [bg, setBg] = useState<string>('');
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (prop.rate <= 3 && prop.rate > 0) {
            setBg('#fcedec');
            setImage('assets/icons/rate-poor.png');
        } else if (prop.rate > 3 && prop.rate <= 4) {
            setBg('#fef5e4');
            setImage('assets/icons/rate-not-bad.png');
        } else if (prop.rate > 4 && prop.rate <= 5) {
            setBg('#e8f8ef');
            setImage('assets/icons/rate-good.png');
        } else {
            setBg('#f7f7f7');
            setImage('assets/icons/rate-no.png');
        }
    }, [prop.rate]);

    return (
        <>
            <div className="container-rate" style={{ backgroundColor: bg }}>
                <img src={`${image}`} alt="" width={43} height={43} />
            </div>
        </>
    );
};
export default Rates;
