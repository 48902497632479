import React from 'react';
import './style.scss';
import { IcardSuggest } from 'interfaces/Icard-suggest';

function CardSuggest(prop: IcardSuggest) {
    const logoImage = `${process.env.PUBLIC_URL}/assets/images/${prop.image}`;

    return (
        <>
            <div className="suggest-card-container">
                    <div className="container-suggest">
                            <div className="suggest-title">{prop.title}</div>
                            <center>
                                <img src={logoImage} className="suggest-img" />
                            </center>
                            <div className="suggest-caption"> {prop.caption} </div>
                        </div>
            </div>
        </>
    );
}

export default CardSuggest;
