import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

if (process.env.NODE_ENV !== 'production') {
  axios.defaults.baseURL = API_URL;
}

export const axiosPublic = axios.create({
  // baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});