import { IinputTextBox } from 'interfaces';
import './style.scss'


const InputBox = (prop:IinputTextBox) => {
    const classes = {
        inputStyle : {
            backgroundColor : prop.isReadOnly ? `#C5C5C5` : ``
        }
    }
    
    return (
        <>
            <input type={prop.typeInput} className={`input-text-box  ${prop.className}` } placeholder={prop.placeholder}  defaultValue={prop.defaultValue} required={prop.isRequired} readOnly={prop.isReadOnly} style={classes.inputStyle} onChange={prop.fucntionCustom}/>
        </>
    )
}

export default InputBox;