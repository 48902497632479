// import Peer from "simple-peer";
import { Buffer } from 'buffer';
import { ButtonTools } from 'components';
import { appInsights } from 'components/interactis/appInsights';
import {
    handleLoadPageEvent,
    isDuplicatedEvent,
    isIgnoredOnRmoteControl,
    onMirror
} from 'components/interactis/untils/replayerMirrorService';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { ConnectionHubContext } from 'contexts';
import moment from 'moment';
import React, { useLayoutEffect, useContext, useEffect, useRef, useState } from 'react';
import { Replayer } from 'rrweb';

import utilService from '../../components/interactis/untils/interactisService';
import WefaceDrawing from './screen-drawing';
// import "../../css/accep-call.css";
import './style.scss';
import VisitorInfo from './visitor-info';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import { MdLiveTv, MdOutlineStopScreenShare } from 'react-icons/md';
import WaitingProcessLoadData from 'components/interactis/WaitingProcessLoadData';
import { CALL_ACTION, CALL_STATUS, COLORS, SESSIONSTORAGE_NAME } from 'utils/constants';
import ClientDisconnected from 'components/interactis/ClientDisconnected';
import { TbRefreshAlert } from 'react-icons/tb';
// import Peer from 'simple-peer';

let interval;

function AcceptCall(props) {
    const { id } = useParams();
    // const { connectionHub, connectionHubId, displayReplayer, styleMarginTopForShareScreen } = props;
    const [connectionHubContext] = useContext(ConnectionHubContext);
    const { connectionHub, connectionHubId } = connectionHubContext;

    const { api } = useServices();


    const [playerWidth, setPlayerWidth] = useState();
    const [playerHeight, setPlayerHeight] = useState();
    const [playerTransform, setPlayerTransform] = useState();

    const playerMaxWidth = window.innerWidth - 528;
    const playerMaxHeight = window.innerHeight - 280;

    const [callStatus, setCallStatus] = useState('rrwebReplayer');
    const [visitingURL, setVisitingURL] = useState(null);
    const [statusShowReplayer, setStatusShowReplayer] = useState(false);
    const [scaleWidth, setScaleWidth] = useState();
    const [userAcceptCallID, setUserAcceptCallID] = useState();
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [visibleScreenSharing, setVisibleScreenSharing] = useState(false);
    const [callControl, setCallControl] = useState();
    const [callSessionID, setCallSessionID] = useState(id);
    const [callSession, setCallSession] = useState();
    const [isReplayerLoading, setIsReplayerLoading] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isBluring, setIsBluring] = useState(false);
    const [statusPaint, setStatusPaint] = useState(false);
    const stageConfig = useRef();
    const startLineConfig = useRef();
    const [positionSalesAgentCurrent, setPositionSalesAgentCurrent] = useState();
    const [isWaitData, setIsWaitData] = useState(false);
    const [isPlayer, setIsPlayer] = useState(false);
    const [senderId, setSenderId] = useState();
    const [receiverId, setReceiverId] = useState();
    const [signalData, setSignalData] = useState();
    const [userStartCallID, setUserStartCallID] = useState();
    const [userConnected, setUserConnected] = useState(true);
    const [isVideoCallReady , setIsVideoCallReady] = useState(false)

    let replayer = null;
    let recordings = [];
    let recentEvent;
    let isChunk = false;
    let chunkBufferString = '';
    const peerWeb = useRef();

    useLayoutEffect(() => {
        if (id) {
            api.calling.getCallSession(id).then((res) => {
                setUserStartCallID(res.data.userStartCallID);
            });
        }
    }, [id]);

    useEffect(() => {
        if (connectionHubId) {
            if (userAcceptCallID == null && sessionStorage.getItem('conhup') == null) {
                interval = setInterval(() => {
                    setUserAcceptCallID(sessionStorage.getItem('conhup'));
                }, 500);
            } else {
                clearInterval(interval);
                sendMessageAcceptCallConnectInteractisVideo(userAcceptCallID, connectionHubId);
            }
        }
    }, [connectionHubId, sessionStorage.getItem('conhup'), userAcceptCallID]);

    useEffect(() => {
        if (connectionHub) {
            connectionHub.on('onMessageCallStatusToAccepCall', (result) => {
                setCallControl(result.callControl);
            });
            connectionHub.on('onMessageWaitData', (result) => {
                setIsWaitData(result.isWaitData);
                setIsPlayer(result.isPlayer);
            });
            connectionHub.on('messageAccepCall', (res) => {
                setUserAcceptCallID(res.userAcceptCallId);
                setCallSessionID(res.callSessionID);

                sessionStorage.setItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_IFRAME, res.userAcceptCallId);
                // sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_IFRAME);
                setIsReplayerLoading(true);
            });
        } else {
            setCallStatus('waitingForCall');
        }
    }, [connectionHub]);

    useEffect(() => {
        if (connectionHub) {
            if (
                sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_ID) ==
                sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_IFRAME)
            ) {
                if (isScreenSharing == false) {
                    if (callControl == 'endcall' || callControl != 'startcall') {
                        setCallStatus('waitingForCall');
                    }
                }

                if (isScreenSharing == undefined) {
                    if (callControl == 'endcall') {
                        setCallStatus('waitingForCall');
                    }
                }
                if (userAcceptCallID != sessionStorage.getItem('conhup')) {
                    setCallStatus('waitingForCall');
                }
            }
        }
    }, [
        connectionHub,
        isScreenSharing,
        userAcceptCallID,
        callControl,
        sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_IFRAME)
    ]);

    useEffect(() => {
        if (connectionHub) {
            handleStatusRRWeb();
            handlePeerWebSignalData();
        }
        return () => {
            isChunk = false;
            if (connectionHub) {
                console.log('>>>> unsubscribe connectionHub events');
                connectionHub.off('StatusrrwebClient');
                connectionHub.off('WebSignalData');
            }
        };
    }, [connectionHub]);

    /**
     * handle client disconnected
     */
    useEffect(() => {
        if (connectionHub && userStartCallID) {
            connectionHub.on('onClientDisconnected', ({ clientConnectionId }) => {
                if (clientConnectionId === userStartCallID) {
                    setUserConnected(false);

                    setTimeout(() => {
                        handleEndCall();
                    }, 5000);
                }
            });
        }
    }, [connectionHub, userStartCallID]);

    useEffect(() => {
        sendMirrorAction({
            drawing: {
                enable: isDrawing,
                statusPaint: statusPaint,
                stageConfig: stageConfig,
                startLineConfig: startLineConfig,
                positionSalesAgentCurrent: positionSalesAgentCurrent
            }
        });
    }, [isDrawing, positionSalesAgentCurrent]);

    useEffect(() => {
        if (isWaitData && isPlayer) {
            if (isScreenSharing) {
                setStatusShowReplayer(true);
                setIsVideoCallReady(true)
            } else {
                setStatusShowReplayer(false);
            }
        }
    }, [isWaitData, isPlayer, isScreenSharing]);

    useEffect(() => {
        if (callControl == 'endcall') {
            setStatusShowReplayer(false);
            setIsDrawing(false);

            replayer = null;
            recordings = [];
            if (document.querySelector('.replayer-wrapper') != null) {
                document.querySelector('.replayer-wrapper').remove();
            }
        }
    }, [callControl, statusShowReplayer]);


    /**
     * Methods
     */
    const handleStatusRRWeb = () => {
        console.log("handleStatusRRWeb");
        connectionHub.on('StatusrrwebClient', (result) => {
            setIsScreenSharing(result.isIFrame);
            if (
                sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_ID) &&
                sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_IFRAME) &&
                sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_ID) ==
                    sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_IFRAME)
            ) {
                if (result.isIFrame) {
                    setIsReplayerLoading(true);
                    if (result.callControl == 'endcall') {
                        setCallStatus('waitingForCall');
                    }
                } 
            }
            if (result.callControl == 'endcall' || result.callControl != 'startcall') {
                setCallStatus('waitingForCall');
                setIsScreenSharing(false);
                replayer = null;
                recordings = [];
            }
        });
    };

    const handlePeerWebSignalData = async () => {
        connectionHub.on('WebSignalData', async ({ receiverId, senderId, signalData }) => {
            setReceiverId(receiverId);
            setSenderId(senderId);
            setSignalData(signalData);

            const iceServers = await getIceServers();
            peerWeb.current = new window.SimplePeer({
                initiator: false,
                trickle: false,
                config: {
                    iceServers: iceServers
                }
            });

            peerWeb.current.on('signal', (data) => {
                if (connectionHub) {
                    connectionHub.send('WebSignalData', senderId, receiverId, JSON.stringify(data));
                }
            });

            peerWeb.current.on('connect', () => {
                console.log('peerWeb on connect');
            });

            peerWeb.current.on('data', (data) => {
                const dataString = Buffer.from(data).toString('utf-8');
                let jsonData;

                if (dataString.includes('isChunkDone')) {
                    jsonData = JSON.parse(chunkBufferString);
                    isChunk = false;
                    chunkBufferString = '';
                } else if (dataString.includes('isChunk')) {
                    isChunk = true;
                } else if (isChunk) {
                    chunkBufferString = chunkBufferString.concat(dataString);
                } else {
                    jsonData = JSON.parse(dataString);
                    setVisitingURL(jsonData.iframeUrl);
                }

                if (!isChunk) {
                    const event = jsonData.eventClient;

                    if (jsonData.isIFrame) {
                        setUserAcceptCallID(jsonData.connectionId);


                        const { screenWidth, screenHeight } = jsonData;

                        setTimeout(() => {
                            updatePlayerSize(screenWidth, screenHeight);
                            updateIFrameUI();
                            setIsReplayerLoading(false);
                        }, 500);
                        // }

                        if (sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_ID) == jsonData.connectionId) {
                            const livePlay = async () => {
                                if (replayer === null && recordings.length >= 1) {
                                    appInsights.trackTrace({
                                        message: 'Sales agent set recordings =' + recordings.length + replayer
                                    });

                                    replayer = new Replayer(recordings, {
                                        liveMode: true,
                                        root: document.getElementById('playerId'),
                                        insertStyleRules: ['#agent-cursor { display: none !important }'],
                                        mouseTail: false
                                        // useVirtualDom: false
                                        // unpackFn: unpack,
                                    });

                                    replayer.on('resize', ({ width, height }) => {
                                        // if (width) setClientWidth(width);
                                        // if (height) setClientHeight(height);
                                    });

                                    appInsights.trackTrace({
                                        message: 'Sales agent set player succeed.',
                                        replayer
                                    });
                                    await connectionHub.send(
                                        'SendStatusPlayer',
                                        sessionStorage.getItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_ID),
                                        true
                                    );

                                    replayer.startLive(event.timestamp);
                                    replayer.enableInteract();

                                    setTimeout(() => {
                                        const onLoad = () => {
                                            setTimeout(() => {
                                                onMirror(replayer.getMirror(), replayer.iframe, (payload) => {
                                                    sendMirrorAction(payload);
                                                });
                                            }, 50);
                                        };

                                        replayer.iframe.addEventListener('load', onLoad);

                                        onMirror(replayer.getMirror(), replayer.iframe, (payload) => {
                                            sendMirrorAction(payload);
                                        });
                                    }, 3000); // 1200
                                } else if (recordings.length < 2) {
                                    appInsights.trackTrace({
                                        message: 'Sales agent set recordings =' + recordings.length,
                                        event
                                    });
                                    await recordings.push(event);
                                }
                                if (replayer !== null) {
                                    if (!isIgnoredOnRmoteControl(event) && !isDuplicatedEvent(event, recentEvent)) {
                                        if (!handleLoadPageEvent(event, replayer.iframe)) {
                                            recentEvent = event;
                                            replayer.addEvent(event);
                                        }
                                    }
                                    removeReplayerLoadingStuck(event);
                                }
                            };

                            const removeReplayerLoadingStuck = (event) => {
                                if (event.sender === 'agent' && isIgnoredOnRmoteControl(event)) {
                                    console.log(
                                        'removeReplayerLoadingStuck',
                                        event.sender,
                                        isIgnoredOnRmoteControl(event)
                                    );

                                    setIsReplayerLoading(false);
                                }
                            };

                            livePlay();
                            setCallStatus('rrwebReplayer');
                        }
                    }
                }
                // console.log("Finished the web peer");
            });

            peerWeb.current.signal(signalData);

            peerWeb.current.on('close', () => {
                console.log('close peer for replayer');
            });
            peerWeb.current.on('error', (err) => {
                console.error('error', err);

                replayer = null;
                recordings = [];
            });

            peerWeb.current._pc.onconnectionstatechange = async () => {
                if (peerWeb.current._pc.connectionState === 'connecting') {
                    console.log('onconnectionstatechange peerWeb connecting');
                }
                if (peerWeb.current._pc.connectionState === 'connected') {
                    console.log('onconnectionstatechange peerWeb connected');
                    setTimeout(() => {
                        const elements = document.querySelectorAll('.replayer-wrapper');
                        if (elements.length > 1) elements[0].remove();
                    }, 250);
                }
                if (peerWeb.current._pc.connectionState === 'failed') {
                    console.log('onconnectionstatechange peerWeb failed');
                }
            };
        });
    };

    const updatePlayerSize = (width, height) => {
        const replayerWrappers = document.querySelectorAll('.replayer-wrapper');
        const widgetRrweb = document.querySelector('.widget-rrweb');

        replayerWrappers.forEach((element) => {
            const newWidth = width > playerMaxWidth ? width : playerMaxWidth;
            const newHeight = height > playerMaxHeight ? height : playerMaxHeight;
            const scaleTransform = playerMaxWidth / newWidth;

            setPlayerTransform(scaleTransform);

            widgetRrweb.style.width = `${newWidth}px`;
            widgetRrweb.style.height = `${newHeight}px`;

            element.style.width = `${newWidth}px`;
            element.style.height = `${newHeight}px`;
            element.style.transform = `scale(${scaleTransform})`;
            element.style.marginLeft = `${(newWidth - width) / 2}px`;
            element.style.marginTop = `${(newHeight - height) / 2}px`;

            setPlayerWidth(newWidth);
            setPlayerHeight(newHeight);
        });
    };

    const updateIFrameUI = () => {
        const iframeElement = document.getElementById('playerId').getElementsByTagName('iframe')[0];
        if (iframeElement) iframeElement.style.border = `3px solid ${COLORS.primary}`;
    };

    const sendMessageAcceptCallConnectInteractisVideo = async (connectionHubIdInVideoCall, connectionHubIdInrrweb) => {
        try {
            await connectionHub.send('SendConnectionId', connectionHubIdInVideoCall, connectionHubIdInrrweb);
        } catch (err) {
            // console.log('error :', err);
        }
    };

    const sendMirrorAction = async (payload) => {
        if (peerWeb && peerWeb.current) {
            try {
                const payloadBytes = Buffer.from(JSON.stringify(payload), 'utf-8');
                peerWeb.current.send(payloadBytes);
            } catch (error) {
                // console.log('Mirror action error: ', error);
            }
        }
    };

    const getIceServers = async () => {
        var iceserverdata = await utilService.getStaticIceServer();
        const iceServers = [{ urls: iceserverdata.data.stunURI }];

        iceserverdata.data.turnServers.forEach((item) => {
            // console.log('check turnServers sales agent', item, moment().format('HH:mm:ss'));
            iceServers.push({
                urls: item.uris,
                username: item.username,
                credential: item.password
            });
        });

        return iceServers;
    };

    const handleEndCall = () => {
        document.getElementById('btn-endcall')?.click();
        setTimeout(() => { window.location.reload()}, 700)
    };

    const handleDraw = () => {
        setIsDrawing(!isDrawing);
    };

    const handleAttachFile = () => {
        document.getElementById('btn-upload').click();
    };

    const handleBlurEffect = () => {
        document.getElementById('btn-bg-blur').click();
        setIsBluring(!isBluring);
    };

    const handleScreenShareReload = () => {
        if (connectionHub) connectionHub.send('SendRRWebReload', senderId);
    };

    return (
        <div>
            <NavbarTop />
            <NavbarLeft />

            <div className="content-page call">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div>
                                <div className="card controller">
                                    <div
                                        className={`card-header ${
                                            !isReplayerLoading && isWaitData && isPlayer ? '' : 'disabled'
                                        }`}>
                                        <div className="visiting-url">
                                            <span>Visiting URL:</span> {visitingURL}
                                        </div>
                                        <div className="actions">
                                            <div style={{ pointerEvents: isVideoCallReady ? 'auto' : 'none' }}>
                                                <ButtonTools
                                                    icon={'pencil'}
                                                    size="32"
                                                    bgColor="#ffffff"
                                                    color={`${isDrawing ? '#6259ce' : '#656565'}`}
                                                    title={`${isDrawing ? 'Stop' : 'Drawing'}`}
                                                    onClick={handleDraw}
                                                />
                                                <ButtonTools
                                                    icon={'clip-paper'}
                                                    size="32"
                                                    bgColor="#ffffff"
                                                    color="#656565"
                                                    title="Send"
                                                    onClick={handleAttachFile}
                                                />
                                                <ButtonTools
                                                    icon={'blur'}
                                                    size="32"
                                                    bgColor="#ffffff"
                                                    color={`${isBluring ? '#6259ce' : '#656565'}`}
                                                    title={`${isBluring ? 'Stop Blur' : 'Blur'}`}
                                                    onClick={handleBlurEffect}
                                                />
                                                <ButtonTools
                                                    customIcon={<TbRefreshAlert size={32} color={'#656565'} />}
                                                    bgColor="#ffffff"
                                                    title={`Reload`}
                                                    onClick={handleScreenShareReload}
                                                />
                                                {/* <ButtonTools
                                                    icon={`upright-downleft`}
                                                    size="32"
                                                    bgColor="#ffffff"
                                                    color="#656565"
                                                    title="Maximize"
                                                /> */}
                                                {/* <ButtonTools
                                                    customIcon={
                                                        <MdOutlineStopScreenShare size={32} color={'#656565'} />
                                                    }
                                                    bgColor="#ffffff"
                                                    title="Screen"
                                                /> */}
                                            </div>
                                            <div>
                                                {/* <ButtonTools
                                                    icon={'recording'}
                                                    size="32"
                                                    bgColor="#ffffff"
                                                    color="#FF1100"
                                                    title="Record"
                                                /> */}
                                                <ButtonTools
                                                    icon={'camera-slash'}
                                                    size="32"
                                                    bgColor="#ffffff"
                                                    color="#FF1100"
                                                    title="End Call"
                                                    onClick={handleEndCall}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="replayer-container">
                                            <div className="rrweb">
                                                {isReplayerLoading && (
                                                    <WaitingProcessLoadData width={playerWidth} height={playerHeight} />
                                                )}

                                                {isWaitData && isPlayer ? (
                                                    <WefaceDrawing
                                                        setIsDrawing={setIsDrawing}
                                                        isDrawing={isDrawing}
                                                        stageConfig={stageConfig}
                                                        startLineConfig={startLineConfig}
                                                        setPositionSalesAgentCurrent={setPositionSalesAgentCurrent}
                                                        positionSalesAgentCurrent={positionSalesAgentCurrent}
                                                        setStatusPaint={setStatusPaint}
                                                        statusPaint={statusPaint}
                                                        playerWidth={playerWidth}
                                                        playerHeight={playerHeight}
                                                        playerTransform={playerTransform}
                                                        scaleWidth={scaleWidth}
                                                        isWaitData={isWaitData}
                                                        isPlayer={isPlayer}
                                                    />
                                                ) : !isReplayerLoading ? (
                                                    <div className="empty-placeholder">
                                                        <h5>Currently no customer screen sharing…</h5>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                                {/* <div
                                                    id="playerId"
                                                    className={`widget-rrweb ${
                                                        true ? 'show' : ''
                                                    }`}
                                                /> */}

<div
                                                    id="playerId"
                                                    className={`widget-rrweb ${
                                                        isScreenSharing && !isReplayerLoading ? 'show' : 'hide'
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {/* video placeholder */}
                                <div className="card video">
                                    <div className="card-body"></div>
                                </div>

                                {/* chat placeholder*/}
                                <div className="card chat">
                                    <div className="card-header"></div>
                                    <div className="card-body"></div>
                                    <div className="card-footer"></div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">{/* <VisitorInfo visitor={callSession} /> */}</div>
                    </div>
                </div>
            </div>

            {callStatus == CALL_STATUS.CALLING && !userConnected && <ClientDisconnected />}

            {/* <EntityListFrameClean> */}

            <Footer />
        </div>
    );
}

export default AcceptCall;
