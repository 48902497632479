import { Badge, Icon, ModalDeleteDialogBox, PageSpinner, Rates, TitleOfPage, TopContentHeader } from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { useEffect, useState } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import { useServices } from 'services';
import { ORDER_BY } from 'utils/constants';
import ResponsivePagination from 'react-responsive-pagination';
import { getScoreRecord, insightColumns } from 'utils/help';
import { Iinsight } from 'interfaces';
import { Modal } from 'react-bootstrap';

const Insight = () => {
    const navigate = useNavigate();
    const { api } = useServices();
    const columns: any = insightColumns();
    const [data, setData]: any = useState<any[]>([]);
    const [loading, setLoading]: any = useState<boolean>(true);
    const [pageNumber, setPageNumber]: any = useState<number>(1);
    const [pageSize, setPageSize]: any = useState<number>(window.innerHeight > 900 ? 8 : 6);
    const [totalpages, setTotalpages]: any = useState<number>(0);
    const [totalItems, setTotalItems]: any = useState<number>(0);
    const [sortDirection, setSortDirection]: any = useState(ORDER_BY.ASC);
    const [sortColumn, setSortColumn]: any = useState('StartTime');
    const [keyword, setKeyword]: any = useState('');
    const [callQueueId, setCallQueueId]: any = useState('');
    const [showModalDeleteEvent, setShowModalDeleteEvent]: any = useState<any>(false);

    const getData = () => {
        api.insight
            .getInsightData(pageNumber, sortDirection, sortColumn, pageSize, keyword)
            .then((res) => {
                let insightDataList = res.data.list;
                let resultInsightDataList = insightDataList.map((item: Iinsight) => {
                    let clientSentimentAnalysis = item.clientSentimentAnalysis;
                    let sentimentObj = JSON.parse(clientSentimentAnalysis);
                    if (sentimentObj?.Sentiment != null) {
                        let resultCalculate = getScoreRecord(sentimentObj);
                        let resultRateScore: any = parseFloat(resultCalculate.sentimentResultScore).toFixed(2);
                        item.scoreRecord = resultRateScore;
                    } else {
                        item.scoreRecord = 0;
                    }
                    return item;
                });
                setData(resultInsightDataList);
                setTotalpages(res.data.totalPages);
                setPageSize(res.data.pageSize);
                setTotalItems(res.data.totalItems);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSorting = (accessor: any) => {
        if (accessor !== '') {
            setSortColumn(accessor);
        }
    };

    const getScoreRecordColor = (score: number) => {
        if (score > 0 && score <= 3) {
            return `#ee6463`;
        } else if (score > 3 && score <= 4) {
            return `#ffc857`;
        } else if (score > 4 && score <= 5) {
            return `#61d595`;
        } else {
            return '';
        }
    };

    const viewDetail = (item: any) => {
        navigate(`/insight/${item.idCallQueues}`);
    };

    const handleDelete = async (id: any) => {
        await api.insight.deleteCallRecord(id);
        getData();
        setShowModalDeleteEvent(false);
    };

    const closeRemoveEventModal = () => {
        setShowModalDeleteEvent(false);
    };

    useEffect(() => {
        getData();
    }, [pageNumber, sortColumn]);

    return (
        <>
            <NavbarTop />
            <NavbarLeft />

            {loading && <PageSpinner />}

            <div className="content-page">
                <div className="container-fluid">
                    <TopContentHeader
                        titleOfPage={`INSIGHT+`}
                        isSearch={false}
                        placeholderSearch={``}
                        hasButton={false}
                        buttonName={``}
                        hasSymbolButton={false}
                        symbolButton={`+`}
                        functionButton={() => {}}
                    />
                    {/* Datatable */}
                    <div className="table-insight">
                        <table className="table-data-insight">
                            <thead>
                                <tr>
                                    {columns.map((item: any, index: number) => (
                                        <th
                                            className={item.cssClass}
                                            onClick={(e) => {
                                                handleSorting(item.accessor);
                                            }}
                                            key={index}>
                                            {item.header === '' ? (
                                                <span className="header-insight">#</span>
                                            ) : (
                                                item.header
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.map((item: Iinsight, index: number) => (
                                        <tr key={index}>
                                            <td>
                                                <Rates rate={item.scoreRecord} />
                                            </td>
                                            <td className="text-agent-custom">{item.saleAgentName}</td>
                                            <td className="text-general-custom text-center">
                                                {moment(item.dateCallRecord).format('D MMM YYYY')}
                                            </td>
                                            <td className="text-general-custom text-center">
                                                {item.startTime ? moment(item.startTime).format('HH:mm') : '-'}
                                            </td>
                                            <td className="text-general-custom text-center">
                                                {item.endTime ? moment(item.endTime).format('HH:mm') : '-'}
                                            </td>
                                            <td className="text-general-custom text-center">
                                                {item.clientName || 'Unknown'}
                                            </td>
                                            <td className="text-center-custom text-center">
                                                <span
                                                    style={{
                                                        color: getScoreRecordColor(item.scoreRecord),
                                                        fontSize: '14px',
                                                        fontWeight: 'bold'
                                                    }}>
                                                    {item.scoreRecord ? item.scoreRecord : ''}
                                                </span>
                                            </td>
                                            <td className="badge-center-custom text-center">
                                                <Badge
                                                    title={item.statusConvertText ? 'Yes' : 'No'}
                                                    bgColor={'#FEFEFE'}
                                                    textColor={item.clientSentimentAnalysis ? '#28C66F' : '#EC5453'}
                                                    borderProperty={
                                                        item.clientSentimentAnalysis
                                                            ? '1px solid #d8f4e4'
                                                            : '1px solid #fae0e0'
                                                    }
                                                />
                                            </td>
                                            <td className="text-center">
                                                <button className="btn btn-icon" onClick={() => viewDetail(item)}>
                                                    {item.statusSentimentAnalysis === 2 ? (
                                                        <img
                                                            src="assets/icons/cricle-graph-available.png"
                                                            alt=""
                                                            className="details"
                                                        />
                                                    ) : (
                                                        <img
                                                            src="assets/icons/cricle-graph-not-available.png"
                                                            alt=""
                                                            className="details"
                                                        />
                                                    )}
                                                </button>
                                            </td>
                                            <td className="text-center">
                                                <button
                                                    className="btn btn-icon"
                                                    onClick={() => {
                                                        setCallQueueId(item.idCallQueues),
                                                            setShowModalDeleteEvent(true)
                                                    }}
                                                    disabled={item.clientSentimentAnalysis ? false : true}>
                                                    <Icon
                                                        icon={`bin`}
                                                        size={28}
                                                        color={item.clientSentimentAnalysis ? `#EC5453` : `#ccc`}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <ResponsivePagination
                            maxWidth={400}
                            current={pageNumber}
                            total={totalpages}
                            onPageChange={setPageNumber}
                        />
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={showModalDeleteEvent} onHide={closeRemoveEventModal}>
                <ModalDeleteDialogBox
                    removeEvent={() => {
                        handleDelete(callQueueId);
                    }}
                    closeModal={closeRemoveEventModal}
                    title={`Deleting Insight`}
                    textAlert={`Are You Sure You Want To Delete This Insight?`}
                />
            </Modal>
        </>
    );
};
export default Insight;
