import Icon from 'components/icon-moon';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { IcardStatistics } from '../../interfaces/Icards';
import './style.scss';

function CardStatistics(prop: IcardStatistics) {
    const useStyles = {
        containerImage: {
            width: '83px',
            height: '83px',
            background: prop.bgColor,
            borderRadius: '20px',
            backgroundRepeat: 'no-repeat',
        },
        summaryStyle: {
            color: prop.summarycolor,
            fontFamily: 'Roboto',
            fontSize: '50px',
            fontWeight: 500,
            marginTop: 20,
        },
    };

    return (
        <>
            <div className="col-lg-3  col-lg-3-5  col-md-6 col-lg-6-5  statistic-widget">
                <div className="card">
                    <div className="card-body">
                        <div className="container-card-contrainer-info">
                            <div className={`mb-2 container-card-title`}>{prop.title}</div>
                            <div className={`container-card-number`} style={useStyles.summaryStyle}>
                                {prop.summary}
                            </div>
                        </div>
                        <div className={`container-card`} style={useStyles.containerImage}>
                            <Icon
                                color={prop.colorIcon}
                                size={35}
                                icon={prop.icon}
                                className="container-card-image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardStatistics;
