import { utils, EventType, IncrementalSource, EventListenerOrEventListenerObject } from 'rrweb';

const INPUT_TAGS = ['INPUT', 'TEXTAREA', 'SELECT'];
const lastInputValueMap = new WeakMap();

const RemoteControlActions = {
    Request: 0,
    MouseMove: 1,
    Click: 2,
    Scroll: 3,
    Input: 4
};

export function onMirror(mirror, iframe, callback) {
    const iframeWindow = iframe.contentWindow;
    const iframeDoc = iframeWindow.document;
    const handlers = [];

    var mouseX = 0;
    var mouseY = 0;

    const mousemoveCb = (evt) => {
        const mouseMovementChecker = (x, y) => {
            var returnValue = false;

            if (mouseX == 0) mouseX = x;

            if (mouseY == 0) mouseY = y;

            if (Math.abs(mouseX - x) > 10 || Math.abs(mouseY - y) > 10) {
                returnValue = true;
                mouseX = x;
                mouseY = y;
            }

            return returnValue;
        };

        if (mouseMovementChecker(evt.offsetX, evt.offsetY)) {
            const target = evt.target;
            preventATag(target);

            callback({
                action: RemoteControlActions.MouseMove,
                id: mirror.getId(target),
                x: evt.clientX,
                y: evt.clientY
            });
        }
    };
    handlers.push(utils.on('mousemove', mousemoveCb, iframeWindow));

    const clickCb = (evt) => {
        const target = evt.target;
        if (!mirror.hasNode(target)) {
            return;
        }

        const aTag = getATagCover(evt.target);

        if (aTag) {
            const hrefVal = aTag.getAttribute('href');
            const isChangingPage = hrefVal.includes('#DoesChangingPage');
            if (isChangingPage) {
                iframeWindow.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }

            callback({
                action: RemoteControlActions.Click,
                aTagId: mirror.getId(aTag),
                // aTagId: aTag.__sn.id,
                isChangingPage
            });
        } else {
            setTimeout(() => {
                callback({
                    action: RemoteControlActions.Scroll,
                    id: mirror.getId(target),
                    x: iframeWindow.scrollX,
                    y: iframeWindow.scrollY
                });
                callback({
                    action: RemoteControlActions.Click,
                    id: mirror.getId(target)
                });
            }, 200);
        }
    };
    handlers.push(utils.on('click', clickCb, iframeWindow));

    const scrollCb = (evt) => {
        setTimeout(() => {
            callback({
                action: RemoteControlActions.Scroll,
                x: iframeWindow.scrollX,
                y: iframeWindow.scrollY
            });
        }, 200);
    };
    handlers.push(utils.on('wheel', scrollCb, iframeWindow));

    const scrollCbTouchMove = (evt) => {
        const target = evt.target;
        if (!mirror.hasNode(target)) {
            return;
        }
        setTimeout(() => {
            callback({
                action: RemoteControlActions.Scroll,
                id: mirror.getId(target),
                x: iframeWindow.scrollX,
                y: iframeWindow.scrollY
            });
        }, 400);
    };
    handlers.push(utils.on('touchmove', scrollCbTouchMove, iframeWindow));

    function eventHandler(event) {
        const target = event.target;
        if (!target || !target.tagName || INPUT_TAGS.indexOf(target.tagName) < 0) {
            return;
        }
        const type = target.type;
        const text = target.value;
        let isChecked = false;
        if (type === 'radio' || type === 'checkbox') {
            isChecked = target.checked;
        }
        cbWithDedup(target, { text, isChecked });
        const name = target.name;
        if (type === 'radio' && name && isChecked) {
            document.querySelectorAll(`input[type="radio"][name="${name}"]`).forEach((el) => {
                if (el !== target) {
                    cbWithDedup(el, {
                        text: el.value,
                        isChecked: !isChecked
                    });
                }
            });
        }
    }

    function cbWithDedup(target, v) {
        const lastInputValue = lastInputValueMap.get(target);
        if (!lastInputValue || lastInputValue.text !== v.text || lastInputValue.isChecked !== v.isChecked) {
            lastInputValueMap.set(target, v);
            callback({
                action: RemoteControlActions.Input,
                id: mirror.getId(target),
                ...v
            });
        }
    }

    ['input', 'change'].forEach((eventName) => handlers.push(utils.on(eventName, eventHandler, iframeDoc)));

    const propertyDescriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');

    const hookProperties = [
        [HTMLInputElement.prototype, 'value'],
        [HTMLInputElement.prototype, 'checked'],
        [HTMLSelectElement.prototype, 'value'],
        [HTMLTextAreaElement.prototype, 'value']
    ];

    if (propertyDescriptor && propertyDescriptor.set) {
        handlers.push(
            ...hookProperties.map((p) =>
                utils.hookSetter(
                    p[0],
                    p[1],
                    {
                        set() {
                            // mock to a normal event
                            eventHandler({ target: this });
                        }
                    },
                    undefined,
                    iframeWindow
                )
            )
        );
    }

    return () => {
        handlers.forEach((h) => h());
    };
}

const preventATag = (target) => {
    let levelLimit = 50;
    let element = target;
    let parentLevel = 0;
    while (element && parentLevel < levelLimit) {
        let href = element.getAttribute('href');

        if (href) {
            href = href.replace(target.origin, '');
            element.setAttribute('href', href.startsWith('/#') || href.startsWith('#') ? href : '#DoesChangingPage');
            element.addEventListener('click', (e) => {
                e.preventDefault();
            });
            element = null;
        } else if (element.nodeName.toLowerCase() !== 'a') {
            element = element.parentElement;
            parentLevel++;
        } else {
            element = null;
        }
    }
};

const getATagCover = (target) => {
    let levelLimit = 50;
    let element = target;
    let parentLevel = 0;
    while (element && parentLevel < levelLimit) {
        if (element.nodeName !== 'A') {
            element = element.parentElement;
            parentLevel++;
        } else {
            return element;
        }
    }

    return null;
};

export function isIgnoredOnRmoteControl(event) {
    switch (true) {
        case event.type === EventType.IncrementalSnapshot && event.data.source === IncrementalSource.MouseInteraction:
        case event.type === EventType.IncrementalSnapshot && event.data.source === IncrementalSource.Input:
            return true;
        default:
            return false;
    }
}

export function isDuplicatedEvent(event, recentEvent) {
    let isDuplicated = false;
    if (
        recentEvent &&
        event.type == recentEvent.type &&
        event.data.source == recentEvent.data.source &&
        event.timestamp == recentEvent.timestamp
    ) {
        isDuplicated = true;
    }

    return isDuplicated;
}

export function handleLoadPageEvent(event, iframe) {
    let isLoading = false;

    if (event.type === EventType.Load) {
        iframe.contentWindow.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        isLoading = true;
    }

    return isLoading;
}
