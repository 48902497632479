import { FiCalendar, FiChevronRight, FiClock, FiWatch } from 'react-icons/fi';
import { BsClockHistory } from 'react-icons/bs';
import { MdOutlineAlarm } from 'react-icons/md';

import { IcardService } from '../../interfaces/Icard-service';
import './style.scss';

function CardService(prop: IcardService) {
    const limitChars = (text: string) => {
        if (text.length < 200) {
            return text;
        }
        return text.substring(0, 199) + '...';
    };

    return (
        <>
            <div className="service-call col-lg-3 col-md-6 col-sm-12 ">
                <div className="card bg-service-card ">
                    <div className="card-header">
                        <img src={prop.Icon} />
                        <h4>{prop.clientName}</h4>
                    </div>
                    <div className="card-body">
                        <div className="widgets">
                            <div>
                                <FiCalendar size={14} color="orange" />
                                <span className="date-text-card">Date: {prop.date}</span>
                            </div>
                            <div>
                                <MdOutlineAlarm size={16} color="#28C66F" />
                                <span className="time-text-card">{prop.time}</span>
                            </div>
                            <div>
                                <BsClockHistory size={13} color="#6259CE" />
                                <span className="minute-text-card">{prop.periodTime} Min</span>
                            </div>
                        </div>
                        <p className="caption-text-card">{limitChars(prop.paragraph)}</p>
                        <div className="caption-clip-card">
                            {/* <FontAwesomeIcon icon={faPaperclip} size="1x" color="#1E1E1E" />
                            <span> {prop.amountClip}</span> */}
                        </div>

                        <div className="read-more" onClick={(e) => prop.readmoreToPopup(e)}>
                            Read More <FiChevronRight size={15} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardService;
