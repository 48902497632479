import { IclientSoundRecord } from 'interfaces';
import fileDownload from 'js-file-download';
import { IoDownloadOutline } from 'react-icons/io5';
import { useServices } from 'services';
import { toast } from 'react-toastify';
import { replaceMessages, toastProperty } from 'utils/help';
import { MESSAGES_ALERT } from 'utils/constants';

const ClientSoundRecord = (props: IclientSoundRecord) => {
    const { api } = useServices();
    const handleDownload = async (event: any) => {
        event.preventDefault();
        api.insight
            .downloadSoundFile(props.callQueuesId, props.clientAudioFileId)
            .then((res: any) => {
                fileDownload(res.data, props.clientAudioFileId + '.mkv');
            })
            .catch((err) => {
                alertMessageError("Client");
            });
    };

    const alertMessageError = (userType : string) =>{
        toast.error(
            replaceMessages(MESSAGES_ALERT.DOWNLOAD_SOUND_FAILED, userType),
            toastProperty('top-left', 2000, false, true, false, true, undefined, 'light')
        );
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h4>Client Sound Record</h4>
                    <span>Record Date: {props.clientStatusRecord != 0 ? props.dateCallRecordCard : '-'}</span>
                    <p>Download sound record</p>

                    <button
                        className="btn btn-primary"
                        onClick={handleDownload}
                        disabled={props.clientStatusRecord != 0 ? false : true}>
                        <IoDownloadOutline size={18} />
                        Download
                    </button>
                </div>
            </div>
        </>
    );
};

export default ClientSoundRecord;
