import { ButtonForm } from 'components';
import { IcardEventButton } from 'interfaces';

import './style.scss';

function CardEventButton(prop: IcardEventButton) {
    return (
        <>
            <div className="card container-card-custom card-event-button">
                <div className="card-body">
                    <div className="title">{prop.title}</div>
                    <div className="sub-title">{prop.subTitle}</div>
                    <div className={`col-lg-6 col-md-6 col-sm-12  mb-3 container-btn-class`}>
                        <ButtonForm
                            title={prop.btnText}
                            className="button-event"
                            clickFunction={(e) => {
                                e;
                            }}
                            typeBtn={`button`}
                            isDisabled={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardEventButton;
