import { axiosPublic } from 'services/axios.public';

export class LocalizationsApi {
    getLocalizations = async (Langauge: string) => {
        const res: any = await axiosPublic.get(`/api/v2/localizations`, {
            headers: {
                'Accept-Langauge': Langauge.toLocaleLowerCase()
            }
        });
        return res.data;
    };
}

export default new LocalizationsApi();
