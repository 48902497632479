import { ItitleOfPage } from 'interfaces';
import React, { useState, useContext, useRef, useEffect } from 'react';
import './style.scss';

function TitleOfPage(prop: ItitleOfPage) {
    // const logoImage = `${process.env.PUBLIC_URL}/assets/images/${prop.image}`;
    return (
        <>
          <h1 className="title-header"> {prop.title}</h1>
        </>
    );
}

export default TitleOfPage;
