import React, { useState, useEffect } from 'react';
import { FaWindowMinimize, FaRegCalendarAlt, FaTrashAlt } from 'react-icons/fa';
import moment from 'moment';

const InteractisAlert = (props) => {
    const [isMinimize, setIsMinimize] = useState(null);
    const [timer, setTimer] = useState('00:00');
    const [duration, setDuration] = useState();
    const {
        serviceCall,
        handleCloseAlert,
        acceptCall,
        callControl,
        confirmCancelServiceCall,
    } = props;

    function toggleShowContainer() {
        setIsMinimize(!isMinimize);
    }
    const handleJoinBtn = () => {
        acceptCall();
        handleCloseAlert();
    };

    let now = moment();
    let start = moment(serviceCall.startTime);
    let diff = parseInt(start.diff(now) / 1000, 10);

    useEffect(() => {
        setDuration(diff);
    }, []);

    useEffect(() => {
        duration >= 0 ? startTimer() : timerClose();
    }, [duration]);

    const startTimer = () => {
        setTimeout(() => {
            let minutes = parseInt(duration / 60, 10);
            let seconds = parseInt(duration % 60, 10);

            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            setTimer(minutes + ':' + seconds);

            setDuration((prevState) => prevState - 1);
        }, 1000);
    };

    const timerClose = () => {
        let now = moment();
        let end = moment(serviceCall.endTime);
        let diff = parseInt(end.diff(now), 10);
        setTimeout(() => {
            handleCloseAlert();
        }, diff);
    };

    return (
        <>
            {isMinimize ? (
                <div
                    className="interactis-alert-minimize-button lg:bottom-12 bottom-36 right-12 transition duration-500 ease-in-out bg-gray-200 text-gray-500 transform hover:-translate-y-1 hover:scale-100 hover:border-transparent focus:border-transparent "
                    onClick={toggleShowContainer}>
                    <div className="flex px-3">
                        <div className="flex text-base text-center m-auto items-center" style={{ color: '#374151' }}>
                            {duration >= 0 ? (
                                <span>{timer} minutes</span>
                            ) : (
                                <span
                                    style={{ color: '#374151' }}
                                    className={
                                        callControl !== 'comingcallfromcode'
                                            ? 'text-gray-400'
                                            : 'text-gray-500'
                                    }>
                                    {callControl !== 'comingcallfromcode'
                                        ? 'Waiting..'
                                        : 'Ready to join'}
                                </span>
                            )}
                            <FaRegCalendarAlt
                                className={`p-1 ml-3 text-4xl ${callControl !== 'comingcallfromcode'
                                        ? 'text-gray-400 '
                                        : 'text-gray-700'
                                    }`}
                                style={{ color: '#374151' }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="transition ease-in duration-700 sm:block interactis-container sm:max-w-lg max-h-60 fixed bg-gray-200 sm:w-full  m-auto inset-0">
                    <div className="w-full h-full bg-red text-center tracking-widest p-3">
                        <div
                            className="absolute text-sm box-border bg-gray-200 flex items-center rounded-2xl hover:border-gray-300 focus:border-gray-300 hover:shadow text-gray-700"
                            onClick={toggleShowContainer}>
                            <FaWindowMinimize />
                        </div>
                        <div
                            className="absolute right-6 text-base box-border bg-gray-200 flex items-center rounded-2xl hover:border-gray-300 focus:border-gray-300 hover:shadow text-red-500"
                            onClick={confirmCancelServiceCall}>
                            <FaTrashAlt />
                        </div>
                        <div className="pt-1 pb-2 w-full">
                            <p className="text-lg uppercase font-bold text-gray-500  tracking-widest">
                                Live Service
                            </p>
                        </div>
                        <div className="py-3  border-b-2 border-t-2 border-solid border-gray-300">
                            {duration >= 0 ? (
                                <>
                                    <p className="text-xl py-2">
                                        Your booked start
                                    </p>
                                    <p className="text-base">
                                        in{' '}
                                        <span className="text-base">
                                            {timer}
                                        </span>{' '}
                                        minutes
                                    </p>
                                </>
                            ) : (
                                <></>
                            )}
                            <p className="text-xl py-2">
                                Client:{' '}
                                <span className="font-semibold">
                                    {serviceCall.clientName}
                                </span>
                            </p>
                            <div
                                className={`h-12 rounded-lg  my-3.5 rounded-full tracking-widest px-12 ${callControl !== 'comingcallfromcode'
                                        ? 'bg-gray-300 text-gray-400'
                                        : ' bg-gray-500 text-gray-100 hover:bg-gray-600 hover:border-transparent focus:border-transparent'
                                    }`}
                                hidden={duration >= 0 ? true : false}
                                onClick={handleJoinBtn}
                                disabled={
                                    callControl !== 'comingcallfromcode'
                                        ? true
                                        : false
                                }>
                                <div className="flex items-center text-xl ">
                                    {callControl !== 'comingcallfromcode' ? (
                                        <span>Waiting</span>
                                    ) : (
                                        <span>Join</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default InteractisAlert;
