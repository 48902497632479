import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface IinputBoxSearch {
    changeFn: (e: any) => void;
    placeholder: string;
}

const InputBoxSearch = (prop:IinputBoxSearch) =>{
    return (
        <div className="input-box-search">
            <input
                type="text"
                className="form-control col-12 input-service-search"
                placeholder={prop.placeholder}
                onKeyUp={prop.changeFn}
            />
            <div className="input-group-append service-search-container">
                <span className="input-group-text input-service-search-icon" id="basic-addon2">
                    <FontAwesomeIcon icon={faSearch} size="1x" />
                </span>
            </div>
        </div>
    );
};

export default InputBoxSearch;
