import {
    DropdownOnchange,
    LoadingProgress,
    ModalDeleteDialogBox,
    ModalServiceCallEntryForm,
    PageSpinner,
    TooltipCalendarEvent,
    TopContentHeader
} from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import './style.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { UserContext } from 'contexts';
import { IagentDropdownItems, IserviceCallByAgentId } from 'interfaces';
import { useServices } from 'services';
import { Modal } from 'react-bootstrap';
import { BookedServiceCalls } from 'partials';
import { ToastContainer, toast } from 'react-toastify';
import { headersContentType, replaceMessages, toastProperty } from 'utils/help';
import { MESSAGES_ALERT } from 'utils/constants';

const CalendarSchedule = () => {
    moment.locale('sv', {
        week: {
            dow: 1,
            doy: 1
        }
    });
    const localizer = momentLocalizer(moment);
    const [userContext] = useContext(UserContext);
    const user = userContext;
    const { api } = useServices();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showButtonDelete, setShowButtonDelete] = useState<any>(false);
    const [reloadContext] = useContext(UserContext);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [salesAgentId, setSalesAgentId] = useState<any>();
    const [salesAgentList, setSalesAgentList] = useState<any>([]);
    const [serviecCallList, setServiecCallList] = useState<any>([]);
    const [currentDate, setCurrentDate] = useState<any>(new Date());
    const [currentView, setCurrentView] = useState<any>('month');
    const [sentStartTime, setSentStartTime] = useState<any>();
    const [sentEndTime, setSentEndTime] = useState<any>();
    const [checkUpdate, setCheckUpdate] = useState<any>(false);
    const [serviceCallId, setServiceCallId] = useState<any>();
    const [serviceCallSelectedOfEventId, setServiceCallSelectedOfEventId] = useState<any>();
    const [titleModal, setTitleModal] = useState<any>();
    const [openBackdrop, setOpenBackdrop] = useState<any>(false);
    const [clientName, setClientName] = useState<string>('');
    const [clientEmail, setClientEmail] = useState<string>('');
    const [clientPhone, setClientPhone] = useState<string>('');
    const [nameServiceCall, setNameServiceCall] = useState<string>('');
    const [descriptionServiceCall, setDescriptionServiceCall] = useState<string>('');
    const [serviecCallByAgentIdToList, setSetServiecCallByAgentIdToList] = useState<any>();
    const [showModalMouseHorver, setShowModalMouseHorver] = useState<boolean>(false);
    const [eventHover, setEventHover] = useState();
    const [showModalDeleteEvent, setShowModalDeleteEvent] = useState<any>(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isValidateFormEvent, setIsValidateFormEvent] = useState<boolean>(false);
    const [serviceId, setServiceId] = useState<number>();
    const [refreshBookingCalls, setRefreshBookingCalls] = useState<boolean>(false);
    const [userRoleLoggedin,setUserRoleLoggedin] = useState<number>(null);


    const formats = {
        weekdayFormat: (date: Date, culture: any, localizer: any) => localizer.format(date, 'dddd', culture)
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const updatingBookedServiceCalls = () => {
        setTimeout(() => {
            setRefreshBookingCalls(false);
        }, 1000);
    };

    const handleHover = (e: any) => {
        let segment: any = e.target.closest('.rbc-row-segment');
        const popup: any = document.querySelector('.popup-event');
        const rbcOverlay: any = e.target.closest('.rbc-event');

        if (rbcOverlay && !segment) {
            let x = rbcOverlay.getBoundingClientRect().left + window.scrollX + 110;
            let y = rbcOverlay.getBoundingClientRect().top + window.scrollY + 40;
            popup.style.top = y + 'px';
            popup.style.left = x + 'px';
        } else {
            if (segment) {
                const positionX: any = segment.getBoundingClientRect().left + window.scrollX + 30;
                const positionY: any = segment.getBoundingClientRect().top + window.scrollY + 40;

                popup.style.top = positionY + 'px';
                popup.style.left = positionX + 'px';
            }
        }
    };

    function Event({ event }) {
        return (
            <div
                className="event-container"
                onMouseOver={(e) => {
                    handleHover(e);
                    setEventHover(event);
                    setShowModalMouseHorver(true);
                }}
                onMouseLeave={(e) => {
                    setShowModalMouseHorver(false);
                }}>
                <div className="title-event">{event.title}</div>
                <div className="title-start-time">{event.start.split('T')[1].substring(0, 5)}</div>
            </div>
        );
    }

    const toggleBackdrop = useCallback(
        (e: any) => {
            setOpenBackdrop(e);
        },
        [openBackdrop]
    );

    const eventStyle = (event: any, start: any, end: any, isSelected: boolean) => {
        const styles = {
            comingEvent: { backgroundColor: '#ff9800' },
            pastEvent: {
                backgroundColor: '#7b7b7b',
                fontStyle: 'italic',
                outline: 'none'
            }
        };
        return {
            style: moment(start) >= moment() ? styles.comingEvent : styles.pastEvent
        };
    };
    const ListData = (items: any) => {
        let newArray = [];
        items.forEach((item: any) => {
            newArray.push({
                id: item.serviceCallId,
                title: item.name,
                desc: item.description,
                start: item.startTime,
                end: item.endTime
            });
        });
        return newArray;
    };

    const getSalesAgentList = useCallback(() => {
        (async () => {
            setLoading(true);
            let result: any = await api.serviceCall.getSalesAgentList();
            let saleAgents: any = result.data;
            let getSaleAgents = [];
            let num = 0;
            saleAgents.forEach((item: IagentDropdownItems) => {
               
                if(item.userId === user.userId){
                    setUserRoleLoggedin(item.role);
                }
               
                
                    let saleAgent = {
                        id: item.userId,
                        text: item.userFullName,
                        value: item.userId,
                        defaultValue: item.userId === user.userId ? true : false
                    };
                    if (saleAgent.defaultValue === true) {
                        setSalesAgentId(item.userId);
                    }
                    getSaleAgents.push(saleAgent);
                    num++;
            });
            setSalesAgentList(getSaleAgents);
            setLoading(false);
        })();
    }, [user]);

    const addFormEventSetupByDate = (event: any): void => {
        setIsValidateFormEvent(false);
        setTitleModal('Add Event');
        setSentStartTime(moment(new Date(event.start)).format('YYYY-MM-DD'));
        setSentEndTime(moment(new Date(event.start)).format('YYYY-MM-DD'));
        setCheckUpdate(false);
        setShowModal(true);
        setShowButtonDelete(false);
        setNameServiceCall('');
        setDescriptionServiceCall('');
        setClientName('');
        setClientEmail('');
        setClientPhone('');
        setServiceCallSelectedOfEventId('');
        setShowModalMouseHorver(false);
        setUserRoleLoggedin(userRoleLoggedin);
    };

    const addFormEventSetup = (): void => {
        setTitleModal('Add Event');
        setIsValidateFormEvent(false);
        setSentStartTime(moment(new Date()).format('YYYY-MM-DD'));
        setSentEndTime(moment(new Date()).format('YYYY-MM-DD'));
        setCheckUpdate(false);
        setShowModal(true);
        setShowButtonDelete(false);
        setShowModalMouseHorver(false);
        setNameServiceCall('');
        setDescriptionServiceCall('');
        setClientName('');
        setClientEmail('');
        setClientPhone('');
        setServiceCallSelectedOfEventId('');
    };

    const handleAddEvent = (e: any) => {
        addFormEventSetup();
    };

    const onSelectSlot = (event: any) => {
        addFormEventSetupByDate(event);
    };

    const onSelectEvent = (event: any) => {
        if (serviecCallList.length > 0) {
            let serviceCall: IserviceCallByAgentId = serviecCallByAgentIdToList.find(
                (x: any) => x.serviceCallId === event.id
            );
            setIsValidateFormEvent(false);
            setTitleModal('Edit Event');
            setShowButtonDelete(true);
            setSentStartTime(serviceCall.startTime);
            setSentEndTime(serviceCall.endTime);
            setCheckUpdate(true);
            setShowModal(true);
            setNameServiceCall(event.title);
            setDescriptionServiceCall(event.desc);
            setClientName(serviceCall.clientName);
            setClientEmail(serviceCall.clientEmail);
            setClientPhone(serviceCall.clientPhoneNumber);
            setServiceCallSelectedOfEventId(serviceCall.serviceCallId);
            setShowModalMouseHorver(false);
            setUserRoleLoggedin(userRoleLoggedin);
        }
    };

    const handleDelete = (id: any) => {
        (async () => {
            setLoading(true);
            let result = await api.serviceCall.cancelServiceCall({
                serviceCallId: id
            });
            if (result.errors && result.errors.length > 0) {
                setErrorMessages(result.errors);
                setLoading(false);
                toast.error(
                    replaceMessages(MESSAGES_ALERT.DELETE_FAILED, `the schedule`),
                    toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
                );
            } else {
                setLoading(false);
                toast.success(
                    replaceMessages(MESSAGES_ALERT.DELETE_SUCCESS, `The schedule`),
                    toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
                );
            }
            setShowModalMouseHorver(false);
            setShowModalDeleteEvent(false);
            setRefreshBookingCalls(true);
            updatingBookedServiceCalls();
        })();
    };

    const handleSalesAgentChange = (e: any) => {
        setSalesAgentId(e.target.value);
        setShowModalMouseHorver(false);
    };

    const settingCurrentDate = (date: any) => {
        setCurrentDate(date);
        computeDisplayedDateRange();
    };

    const settingCurrentView = (view: any) => {
        setCurrentView(view);
        computeDisplayedDateRange();
    };

    const computeDisplayedDateRange = () => {
        let startMoment = moment(currentDate).startOf(currentView);
        let endMoment = moment(currentDate).endOf(currentView);

        let start = startMoment.format();
        let end = endMoment.format();

        if (currentView == 'month') {
            startMoment = startMoment.startOf('week');
            endMoment = endMoment.endOf('week');
        }
        setStartTime(start);
        setEndTime(end);
    };

    const convertDate = (date: string) => {
        let mainDate = date.split('-');
        let day: string = mainDate[0];
        let month: string = mainDate[1];
        let year: string = mainDate[2];
        return year + '-' + month + '-' + day;
    };

    const alertMessageFormAddAndUpdate = (result: any, isCreated: boolean) => {
        var messageAlertFailed = '';
        var messageAlertSeccess = '';
        if (isCreated) {
            messageAlertSeccess = MESSAGES_ALERT.SAVE_SUCCESS;
            messageAlertFailed = MESSAGES_ALERT.SAVE_FAILED;
        } else {
            messageAlertSeccess = MESSAGES_ALERT.UPDATE_SUCCESS;
            messageAlertFailed = MESSAGES_ALERT.UPDATE_FAILED;
        }

        if (result.errors && result.errors.length > 0) {
            setErrorMessages(result.errors);
            if (isCreated) {
                setShowModal(true);
                setLoading(false);
            }
            toast.error(
                replaceMessages(messageAlertFailed, `the schedule`),
                toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
            );
        } else {
            if (isCreated) {
                setShowModal(false);
                setLoading(false);
            }
            toast.success(
                replaceMessages(messageAlertSeccess, `The schedule`),
                toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
            );
        }
    };

    const processMethod = async (isCreated: boolean, formData: FormData) => {
        if (isCreated) {
            setLoading(true);
            let result = await api.serviceCall.createServiceCall(formData, {
                headers: headersContentType()
            });
            alertMessageFormAddAndUpdate(result, true);
        } else {
            setLoading(true);
            let result = await api.serviceCall.updateServiceCall(formData, {
                headers: headersContentType()
            });
            alertMessageFormAddAndUpdate(result, false);
            setShowModal(false);
            setShowModalMouseHorver(false);
            setLoading(false);
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsValidateFormEvent(true);

        let title: string = event.target[0].value;
        let description: string = event.target[1].value;
        let startNewDate: string = convertDate(event.target[4].value) + 'T' + event.target[2].value;
        let endNewDate: string = convertDate(event.target[4].value) + 'T' + event.target[3].value;
        let salesId: string = event.target[5].value;
        let clientOfName: string = event.target[6].value;
        let clientOfEmail: string = event.target[7].value;
        let clientNumber: string = event.target[8].value;

        toggleBackdrop(true);
        const formData = new FormData();

        if (checkUpdate == false) {
            formData.append('name', title || '');
            formData.append('description', description || '');
            formData.append('startTime', startNewDate || '');
            formData.append('endTime', endNewDate || '');
            if (user.userRole != 2) {
                formData.append('saleAgentId', salesId || '');
            } else {
                formData.append('saleAgentId', user.userId || '');
            }
            formData.append('clientName', clientOfName || '');
            formData.append('clientEmail', clientOfEmail || '');
            formData.append('clientPhoneNumber', clientNumber || '');
            await processMethod(true, formData);
            setRefreshBookingCalls(true);
            updatingBookedServiceCalls();
        } else {
            formData.append('serviceCallId', serviceCallSelectedOfEventId || '');
            formData.append('name', title || '');
            formData.append('description', description || '');
            formData.append('startTime', startNewDate || '');
            formData.append('endTime', endNewDate || '');
            if (user.userRole != 2) {
                formData.append('saleAgentId', salesId || '');
            } else {
                formData.append('saleAgentId', user.userId || '');
            }
            formData.append('clientName', clientOfName || '');
            formData.append('clientEmail', clientOfEmail || '');
            formData.append('clientPhoneNumber', clientNumber || '');

            await processMethod(false, formData);
            setRefreshBookingCalls(true);
            updatingBookedServiceCalls();
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const removeEventIndividual = (eventId: number) => {
        setServiceId(eventId);
        setShowModalDeleteEvent(true);
        setShowModalMouseHorver(false);
    };
    const closeRemoveEventModal = () => {
        setShowModalDeleteEvent(false);
        setShowModalMouseHorver(false);
    };

    useEffect(() => {
        computeDisplayedDateRange();
        if (salesAgentId == undefined) {
            getSalesAgentList();
        }
    }, [currentDate, reloadContext]);

    useEffect(() => {
        if (user.userRole == 2) {
            setSalesAgentId(user.userId);
        }
        (async () => {
            if (
                startTime &&
                endTime &&
                salesAgentId != undefined &&
                showModal === false &&
                showModalDeleteEvent === false
            ) {
                let result: any = await api.serviceCall.getServiceCallsCalendar(startTime, endTime, salesAgentId);
                if (result.data != null) {
                    let callId: any = result.data.items[0] ? result.data.items[0].serviceCallId : null;
                    let listData = ListData(result.data.items);
                    setServiecCallList(listData);
                    setServiceCallId(callId);
                    setSetServiecCallByAgentIdToList(result.data.items);
                }
            }
        })();
    }, [startTime, endTime, salesAgentId, showModal, showModalDeleteEvent]);

    return (
        <>
            <NavbarTop />
            <NavbarLeft />
            {loading && <PageSpinner />}
            <ToastContainer />
            <div className="content-page">
                <div className="popup-event" style={{ display: showModalMouseHorver ? `` : `none` }}>
                    <TooltipCalendarEvent
                        toolTipExit={() => setShowModalMouseHorver(false)}
                        toolTipDelCalendarEventIndividual={(id) => {
                            setServiceCallId(id);
                        }}
                        serviceCallId={serviceCallId}
                        removeEventIndividual={removeEventIndividual}
                        dataAll={serviecCallByAgentIdToList}
                        dataIndividual={eventHover}
                    />
                </div>
                <div className="container-fluid">
                    <TopContentHeader
                        titleOfPage={`CALENDAR`}
                        isSearch={false}
                        placeholderSearch={``}
                        hasButton={true}
                        buttonName={`Add Event`}
                        hasSymbolButton={true}
                        symbolButton={`+`}
                        functionButton={handleAddEvent}
                    />
                    <div className="container-option-top">
                        <div className="sales-agent">
                            <label>Sales Agent</label>
                            <DropdownOnchange
                                data={salesAgentList}
                                border={`1px solid`} //Property
                                optionOnChange={(e: any) => handleSalesAgentChange(e)}
                                isReadOnly={false}
                            />
                        </div>
                    </div>
                    <div className="container-calendar">
                        <Calendar
                            selectable
                            localizer={localizer}
                            events={serviecCallList}
                            style={{ height: `calc(100vh - 170px)` }}
                            views={['month']}
                            defaultDate={moment().format('YYYY-MM-DD')}
                            formats={formats}
                            popup={true}
                            onSelectEvent={(event) => (moment(event.start) >= moment() ? onSelectEvent(event) : false)}
                            onSelectSlot={(event) =>
                                moment(event.start).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
                                    ? onSelectSlot(event)
                                    : false
                            }
                            components={{
                                event: Event
                            }}
                            eventPropGetter={eventStyle}
                            onNavigate={settingCurrentDate}
                            onView={settingCurrentView}
                        />
                    </div>
                    <BookedServiceCalls refreshBookingCall={refreshBookingCalls} />
                </div>
            </div>
            {
                <>
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <ModalServiceCallEntryForm
                                userRoleLoggedIn = {userRoleLoggedin}
                                showButtonDelete={showButtonDelete}
                                onClose={closeModal}
                                startTime={sentStartTime}
                                endTime={sentEndTime}
                                selectedsalesAgentId={salesAgentId}
                                checkUpdate={checkUpdate}
                                title={nameServiceCall}
                                handleOpenSnackbar={() => {}}
                                toggleBackdrop={toggleBackdrop}
                                description={descriptionServiceCall}
                                titleHeader={titleModal}
                                clientName={clientName}
                                clientEmail={clientEmail}
                                clientPhoneNumber={clientPhone}
                                errorMessage={errorMessages}
                                isValidateFormEvent={isValidateFormEvent}
                            />
                        </form>
                    </Modal>
                    <Modal show={showModalDeleteEvent} onHide={closeRemoveEventModal}>
                        <ModalDeleteDialogBox
                            removeEvent={() => {
                                handleDelete(serviceId), setServiceCallId(serviceId);
                            }}
                            closeModal={closeRemoveEventModal}
                            title={`Deleting Event`}
                            textAlert={`Are You Sure You Want To Delete This Event?`}
                        />
                    </Modal>
                </>
            }

            <Footer />
        </>
    );
};

export default CalendarSchedule;
