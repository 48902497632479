import { Modal } from 'react-bootstrap';
import './style.scss';
import { ItextCallRecord } from 'interfaces';

function ViewTextCallRecord(props: ItextCallRecord) {
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.clientConvertText ? 'Client Text Conversion' : 'Sales Agent Text Conversion'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="body-content">
                <span>{props.clientConvertText ? props.clientConvertText : props.salesAgentConvertText}</span>
            </Modal.Body>
        </>
    );
}

export default ViewTextCallRecord;
