import './style.scss';

interface Iloading {
    isEnabled: boolean;
}

const LoadingProgress = (prop: Iloading) => {
    return (
        <>
            {prop.isEnabled ? (
                <div id="container-loading">
                    <div id="loading-v3"></div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default LoadingProgress;
