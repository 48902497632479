// @/src/common/axiosPrivate.js
import axios from 'axios';

import { memoizedRefreshToken } from './refreshToken';

const API_URL = process.env.REACT_APP_API_URL;

if (process.env.NODE_ENV !== 'production') {
    axios.defaults.baseURL = API_URL;
}

const errorHandler = async (error) => {
    console.log('error =========> ', error);

    const config = error?.config;
    const statusCode = error?.response?.status;
    const errResponse = error?.response;
    let errMessage = errResponse?.data?.message || error.toString();

    try {
        errMessage = errMessage.replace('AxiosError: ', '');
    } catch (error) { }

    // logging only errors that are not 401
    if (statusCode && statusCode == 400) {
    }

    // logging only errors that are not 401
    if (statusCode === 401 && !config?.sent) {
        config.sent = true;

        const res = await memoizedRefreshToken();

        if (res?.access_token) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${res?.access_token}`
            };
        }

        return axios(config);
    }

    if (statusCode && statusCode == 404) {
    }

    if (statusCode && statusCode >= 500) {
    }

    try {

    } catch (ex) {
        console.error(`Can't create error logs`);
    }

    return Promise.reject({
        statusCode,
        message: errMessage
    });
};

axios.interceptors.request.use(
    async (config) => {
        const session = JSON.parse(sessionStorage.getItem('auth'));

        if (session?.access_token) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${session?.access_token}`
            };
        }

        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        return errorHandler(error);
    }
);

export const axiosPrivate = axios;
