import { axiosPrivate } from 'services/axios.private';

export class CallSettingApi {
    getCallSetting = async () => {
        const res = await axiosPrivate
            .request({
                url: `/api/v2/setting/query/get-call-settings`,
                method: 'GET'
            })
            .catch((err) => {
                return err;
            });

        return res.data?.data;
    };

    updateCallSetting = async (formData: any) => {
        const res = await axiosPrivate.post(`/api/v2/setting/command/update-call-setting`, formData).catch((err) => {
            return err;
        });

        return res;
    };

    updateWefaceDisplay = async (formData: FormData, params: any) => {
        const res = await axiosPrivate
            .post(`/api/v2/setting/command/update-close-portal`, formData, params)
            .catch((err) => {
                return err;
            });

        return res;
    };

    getThemeOption = async () => {
        const res = await axiosPrivate
            .request({
                url: `/api/v2/setting/query/get-theme-options`,
                method: 'GET'
            })
            .catch((err) => {
                return err;
            });

        return res.data?.data;
    };

    updateThemeOption = async (formData: FormData, params: any) => {
        const res = await axiosPrivate
            .post(`/api/v2/setting/command/update-theme-options`, formData, params)
            .catch((err) => {
                return err;
            });

        return res;
    };
}
