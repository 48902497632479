import {
    ButtonTriggerToggleSlidePreview,
    Icon,
    ModalDeleteDialogBox,
    PageSpinner,
    PageToolbar,
    PaginationResponsiveCustomfrom,
    TopContentHeader
} from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useServices } from 'services';
import ButtonToggle from 'components/button-toggle';
import { Modal } from 'react-bootstrap';
import { formatDateTime, headersContentType, replaceMessages, toastProperty } from 'utils/help';
import { Itriggers } from 'interfaces';
import { ToastContainer, toast } from 'react-toastify';
import { BiPlus } from 'react-icons/bi';
import { MESSAGES_ALERT } from 'utils/constants';

const Trigger = () => {
    const navigate = useNavigate();
    const { api } = useServices();
    const [triggerData, setTriggerData] = useState<any>(Array);
    const [loading, setLoading] = useState<boolean>(false);

    const [sortDirection] = useState<string>('asc');
    const [sortColumns] = useState<string>('');
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize] = useState<number>(5);
    const [totalpages, setTotalpages] = useState<number>(0);
    const [searchTriggers, setSeachTriggers] = useState<any>('');
    const [showModalDeleteTrigger, setShowModalDeleteTrigger] = useState<boolean>(false);
    const [triggerIdForDeleting, setTriggerIdForDeleting] = useState(null);
    const [isActivate, setIsActivate] = useState<boolean>(null);
    const [idTrigger, setIdTrigger] = useState<number>(null);

    const fetchData = async () => {
        setTriggerData([]);
        setLoading(true);
        let result: any = await api.trigger.getTriggerData(
            pageNumber,
            sortDirection,
            sortColumns,
            pageSize,
            searchTriggers
        );
        setTriggerData(result.list);
        setTotalpages(result.totalPages);
        setLoading(false);
    };

    const viewDetail = (item: any) => {
        navigate(`/triggers/update/${item.id}`);
    };

    useEffect(() => {
        fetchData();
    }, [searchTriggers]);

    useEffect(() => {
        fetchData();
    }, [pageNumber]);

    const closeRemoveTriggerModal = () => {
        setShowModalDeleteTrigger(false);
    };


    //HandleActivate
    useEffect(() => {
        if (idTrigger) {
            const formData = new FormData();
            formData.append('active', isActivate.toString());
            api.trigger.updateActivate(idTrigger, formData, headersContentType());
        }
    }, [isActivate, idTrigger]);

    const handleDeleteToTrigger = useCallback((id: any) => {
        setLoading(true);
        (async () => {
            await api.trigger.deleteTrigger(id);
            setPageNumber(1);
            fetchData();
            setLoading(false);
            toast.success(
                replaceMessages(MESSAGES_ALERT.DELETE_SUCCESS, `The trigger`),
                toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
            );
            setShowModalDeleteTrigger(false);
        })();
    }, []);

    return (
        <>
            <NavbarTop />
            <NavbarLeft />

            {loading && <PageSpinner />}

            <ToastContainer />
            <div className="content-page trigger-app">
                <div className="container-fluid">
                    <TopContentHeader
                        titleOfPage={`TRIGGERS`}
                        isSearch={true}
                        placeholderSearch={`Search...`}
                        hasButton={true}
                        buttonName={`Add Trigger`}
                        hasSymbolButton={true}
                        symbolButton={<BiPlus color={`#fff`} size={20} />}
                        functionButton={() => navigate('create')}
                        functionForSearch={(e) => setSeachTriggers(e.target.value)}
                    />

                    <PageToolbar />

                    <table>
                        <tbody>
                            {triggerData.map((item: Itriggers, i: number) => (
                                <tr key={i}>
                                    <td onClick={() => viewDetail(item)}>
                                        <ButtonTriggerToggleSlidePreview
                                            message={item.message}
                                            topPosition={0}
                                            leftPosition={0}
                                            rightPosition={0}
                                            bottomPosition={0}
                                            isPreview={true}
                                            backgroundColor={item.color}
                                            imageUrl={item.imageUrl === 'undefined' ? null : item.imageUrl}
                                        />
                                    </td>
                                    <td>
                                        <div className="info">
                                            <div className="info-item">
                                                <label>Title:</label>
                                                <span>{item.title}</span>
                                            </div>
                                            <div className="info-item">
                                                <label>Activate:</label>
                                                <span style={{ marginTop: 5 }}>
                                                    <ButtonToggle
                                                        defaultChecked={item.active}
                                                        onToggle={(e: any) => {
                                                            setIsActivate(e), 
                                                            setIdTrigger(item.id);
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                            <div className="info-item">
                                                <label title={item.pageUrl} className="url">
                                                    Page Url:
                                                </label>
                                                <span title={item.pageUrl}>{item.pageUrl}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="info">
                                            <div className="info-item period">
                                                <label className="period">Period:</label>
                                                <span className="period">{formatDateTime(item.startAt)}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-icon user-delete"
                                            onClick={(e) => {
                                                setShowModalDeleteTrigger(true), setTriggerIdForDeleting(item.id);
                                            }}>
                                            <Icon icon={`bin`} size={24} color={`#EC5453`} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModalDeleteTrigger} onHide={closeRemoveTriggerModal}>
                <ModalDeleteDialogBox
                    removeEvent={() => handleDeleteToTrigger(triggerIdForDeleting)}
                    closeModal={closeRemoveTriggerModal}
                    title={`Deleting Trigger`}
                    textAlert={`Are You Sure You Want To Delete This Trigger?`}
                />
            </Modal>
            <PaginationResponsiveCustomfrom
                position={`right`}
                pageNumber={pageNumber}
                totalpages={totalpages}
                onPageChanged={setPageNumber}
            />
            <Footer />
        </>
    );
};
export default Trigger;
