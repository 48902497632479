import './style.scss';
import { IbuttonForm } from '../../interfaces/Ibutton-form';

function ButtonForm(prop: IbuttonForm) {
    let classNameCustom = '';
    let isIcon = false;
    var typeButton:any = ``

    if(prop.typeBtn === `submit` || prop.typeBtn === `reset` || prop.typeBtn === `button`){
        typeButton = prop.typeBtn
    }else{
        typeButton = `button`
    }

    if (prop.className === '') {
        classNameCustom = 'btn weface-button-btn';
        isIcon = false;
    } else {
        classNameCustom = prop.className;
    }

    return (
        <>
            {isIcon ? (
                <button
                    type={typeButton}
                    className={`${classNameCustom}`}
                    onClick={e => prop.clickFunction(e)}
                    disabled={prop.isDisabled}
                >
                    {prop.title}
                </button>
            ) : (
                <button
                    type={typeButton}
                    className={`${classNameCustom}`}
                    onClick={e => prop.clickFunction(e)}
                    disabled={prop.isDisabled}
                >
                    {prop.title}
                </button>
            )}
        </>
    );
}

export default ButtonForm;
