// import { Weface } from 'partials';
import React from 'react';

import './style.scss';

const Footer = (props: any) => {
    return (
        <>

            {/* caching images */}
            <div className="d-none">
                <img src="/assets/icons/call.gif" />
                <img src="/assets/icons/typing.gif" />
                <img src="/assets/icons/accept-xs.png" />
                <img src="/assets/icons/decline-xs.png" />
            </div>

            {/* <InteractisVideo /> */}

            {/* <footer className="iq-footer">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <a href="../backend/privacy-policy.html">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="../backend/terms-of-service.html">
                        Terms of Use
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 text-right">
                  <span className="mr-1">
                    <script>document.write(new Date().getFullYear())</script>©
                  </span>{" "}
                  <a href="#" className="">
                    Weface
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
        </>
    );
};

export default Footer;
