import './style.scss';
import ReactHtmlParser from 'react-html-parser';
import { useEffect, useState } from 'react';
import { headersContentType, isImageType } from 'utils/help';
import { useServices } from 'services';

interface IbuttonTriggerToggleSlidePreview {
    message: any;
    topPosition: any;
    leftPosition: any;
    rightPosition: any;
    bottomPosition: any;
    isPreview: boolean;
    resultUriImage? : any;
    backgroundColor?: string;
    imageUrl?: string;
}

const ButtonTriggerToggleSlidePreview = (prop: IbuttonTriggerToggleSlidePreview) => {
    const [messageToview, setMessageToview] = useState<any>(null);
    const [alertOverSizeFile, setAlertOverSizeFile] = useState(null);
    const [profileFile, setProfileFile] = useState<any>(null);
    const [backgroundColor, setBackgroundColor] = useState<string>(prop.backgroundColor);
    const { api } = useServices();
    const unix = Math.round(+new Date() / 1000);

    const useStyles = {
        custom: {
            marginTop: prop.topPosition + 'px',
            marginLeft: prop.leftPosition + 'px',
            marginBotton: prop.bottomPosition + 'px',
            marginRight: prop.rightPosition + 'px'
        }
    };

    const uploadSingleFile = async(e: any) => {
        new FormData();
        let inputFile = e.target.files[0];
        if (inputFile && isImageType(inputFile.name)) {
            if (inputFile.size > 10000000) {
                if (inputFile && inputFile.current) inputFile.current.value = '';
                setAlertOverSizeFile('Your image is oversize, Please can you upload your image again.');
            } else {
                
                const formData = new FormData();
                formData.append('File', e.target.files[0]);
                let result = await api.trigger.uploadImageSingle(formData, headersContentType());
                setAlertOverSizeFile(null);
                setProfileFile(result.uri);
                prop.resultUriImage(result.uri)
            }
        }
    };

    

    useEffect(() => {
        setMessageToview(prop.message);
        setBackgroundColor( prop.backgroundColor);
    }, [prop]);


    return (
        <>
            <div className={`trigger-toggle-slide-preview-component`} style={useStyles.custom}>
                <div className="preview-item">
                    <>
                        {profileFile ? (
                            <div className="avatar" style={{ backgroundImage: `url(${profileFile}?v=${unix})` }}></div>
                        ) : 
                        prop.imageUrl ? 
                            (
                                <div
                                    className="avatar"
                                    style={{ backgroundImage: `url(${prop.imageUrl}?v=${unix})` }}></div>
                            )
                        :
                            (
                                <div
                                    className="avatar"
                                    style={{ backgroundImage: `url('/assets/icons/empty-img.png')` }}></div>
                            )
                    
                    }

                        {prop.isPreview === false ? (
                            <input
                                accept="image/*"
                                className={`icon-edit`}
                                id="contained-button-file"
                                type="file"
                                onChange={uploadSingleFile}
                            />
                        ) : (
                            ''
                        )}

                        <div id="trigger-message-box" className="message-box" style={{backgroundColor:backgroundColor}}>
                            {ReactHtmlParser(messageToview)}
                        </div>
                        {alertOverSizeFile ? <div className="error-message">{alertOverSizeFile}</div> : ''}
                    </>
                </div>
            </div>
        </>
    );
};

export default ButtonTriggerToggleSlidePreview;
