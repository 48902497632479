import { useState, useContext, useEffect } from 'react';
import './style.scss';
import { useNavigate, NavLink } from 'react-router-dom';
import { useServices } from 'services';
import { LoginContext, TranslationContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { replaceMessages, toastProperty } from 'utils/help';
import { MESSAGES_ALERT } from 'utils/constants';

const bgImage = `${process.env.PUBLIC_URL}/assets/images/bg-image.jpg`;
const logoImage = `${process.env.PUBLIC_URL}/assets/images/logo-weface-new.png`;

function lookingPassword() {
    const togglePassword: any = document.querySelector('#togglePassword');
    const togglePass: any = document.getElementById('togglePassword');
    const passwordV: any = document.querySelector('#passwordId');
    const type: any = passwordV.getAttribute('type') === 'password' ? 'text' : 'password';
    togglePassword.className === 'bi bi-eye'
        ? (togglePass.className = 'bi bi-eye-slash')
        : (togglePass.className = 'bi bi-eye');
    passwordV.setAttribute('type', type);
}

function Login() {
    const navigate = useNavigate();
    const { api } = useServices();
    const [loginContext, setLoginContext] = useContext(LoginContext);
    const [translation, setTranslation] = useContext(TranslationContext);
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [invalid, setInvalid] = useState(false);
    let isChecked: boolean = true;

    const loginToDashboard = async (event: any) => {
        event.preventDefault();
        const auth = await api.auth.login(username, password);
        if (auth?.access_token) {
            if (auth && auth.access_token) {
                auth.remember_me = isChecked;
                setLoginContext(auth);
                navigate('/');
            } else {
                navigate('/login');
                setInvalid(true);
                toast.error(
                    replaceMessages(MESSAGES_ALERT.LOGIN_FAILED, ``),
                    toastProperty('top-right', 2000, false, true, false, true, undefined, 'light')
                );
            }
        } else {
            navigate('/login');
            setInvalid(true);
            toast.error(
                replaceMessages(MESSAGES_ALERT.LOGIN_FAILED, ``),
                toastProperty('top-right', 2000, false, true, false, true, undefined, 'light')
            );
        }
    };

    const onChangeCheckbox = (event: any) => {
        isChecked = event.target.checked;
    };

    return (
        <>
            <ToastContainer />
            <div className="login-page">
                <div className="wrapper bg-login" style={{ backgroundImage: `url(${bgImage})` }}>
                    <section className="login-content">
                        <div className="logo" style={{ backgroundImage: `url(${logoImage})` }}></div>
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="card-container">
                                    <div className="card auth-card">
                                        <div className="card-body p-0">
                                            <div className="d-flex align-items-center auth-content">
                                                <div className="col-lg-12 align-self-center">
                                                    <div className="title-text-top-container">
                                                        <h3 className="mb-5 title-text-top">
                                                            {t(translation.LOGIN_OVERLINE)}
                                                        </h3>

                                                        <form
                                                            onSubmit={loginToDashboard}
                                                            className="container-form-login">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="floating-label form-group">
                                                                        <input
                                                                            className="floating-input form-control input-login-auth"
                                                                            autoComplete="email"
                                                                            type="email"
                                                                            onChange={(e) =>
                                                                                setUsername(e.target.value)
                                                                            }
                                                                            placeholder="Email"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="floating-label form-group password-container">
                                                                        <input
                                                                            className="floating-input form-control input-login-auth"
                                                                            type="password"
                                                                            placeholder="Password"
                                                                            onChange={(e) =>
                                                                                setPassword(e.target.value)
                                                                            }
                                                                            id="passwordId"
                                                                        />
                                                                        <i
                                                                            className="bi bi-eye"
                                                                            onClick={lookingPassword}
                                                                            id="togglePassword"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="custom-control custom-checkbox remember-radio mb-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="customCheck1"
                                                                            aria-checked={isChecked}
                                                                            checked={isChecked}
                                                                            onChange={onChangeCheckbox}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label control-label-1 remember-radio-text mb-3"
                                                                            htmlFor="customCheck1">
                                                                            {t(translation.REMEMBER_ME)}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="submit" className="button-login">
                                                                {t(translation.LOGIN_BUTTON)}
                                                            </button>
                                                            <div className="mt-3 text-center">
                                                                <NavLink
                                                                    to={`/forgot-password`}
                                                                    className="forgot-password">
                                                                    {t(translation.FORGET_YOUR_PASSOWORD)}
                                                                </NavLink>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Login;
