import React from 'react';

import { ApiService } from './api';

class Services {
    api = new ApiService();
}
export const services = new Services();

const servicesContext = React.createContext<Services>(services);
export const ServicesProvider = ({ children }: any) => (
    <servicesContext.Provider value={services}>{children}</servicesContext.Provider>
);
export const useServices = (): Services => React.useContext(servicesContext);

export const initServices = async (): Promise<void> => {
    for (const key in services) {
        if (Object.prototype.hasOwnProperty.call(services, key)) {
            const s = (services as any)[key];

            if (s.init) {
                await s.init();
            }
        }
    }
};