import { faTrashCan, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from 'components/icon-moon';
import './style.scss'

interface IdeleteEventIndividual {
    removeEvent : (e: any) => void;
    closeModal : (e: any) => void;
    title : string;
    textAlert : string;
}

const ModalDeleteDialogBox = (prop:IdeleteEventIndividual) =>{ 
  
    return(
    <>
    <div className="containter-dialog-remove">
    <div className="containter-dialog-remove-box">
        <div className="dialog-remove-bin"><Icon icon='trash-can-full' size={100} color={'#EC5453'}/></div>
        <div className="dialog-remove-content">
            <div className="dialog-remove-content-title">{prop.title}</div>
            <div className="dialog-remove-content-subtitle">{prop.textAlert}</div>
        </div>
        
    </div>
    <div className="containter-dialog-remove-footer">
    <div className="dialog-remove-cancel-btn"  onClick={ e=> prop.closeModal(e)}>
        <span >
            {' '}
            <FontAwesomeIcon icon={faX} size={`1x`} />
        </span>
         <span>Cancel</span>
         </div>
        <div className="dialog-remove-del-btn" onClick={ e => prop.removeEvent(e) }>
        <span className='icon'>
            {' '}
            <Icon icon='trash-Can' size={21} color={'#FFFFFF'}/>
        </span>
            <span className='text'>{prop.title}</span>
        </div>
    </div>
    </div>
   
    
    </>
    
    )
}
export default ModalDeleteDialogBox;