import { CardStatistics, InvitationLink, PageSpinner, SessionView, VisitorsAnimation } from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { BookedServiceCalls, CurrentVisitors, DashboardStatisticWidgets } from 'partials';
import { useEffect, useState } from 'react';
import { useServices } from 'services';

import './style.scss';

const Dashboard: React.FC = () => {
    const { api } = useServices();
    // local states
    const [statistic, setStatistic] = useState<any>({});
    const [selectedClient, setSelectedClient] = useState<any>({});
    const [statusShareScreenClient, setStatusShareScreenClient] = useState();
    const [loading, setLoading] = useState(true);

    // methods
    useEffect(() => {
        api.dashboard
            .getStatistic()
            .then((res) => {
                setStatistic(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // renderers

    return (
        <div>
            <NavbarTop />
            <NavbarLeft />
            {loading && <PageSpinner />}

            <div className="content-page">
                <div className="container-fluid">
                    {/* Statistic */}
                    <DashboardStatisticWidgets statistic={statistic} />

                    {/* Client Screen Player */}
                    <SessionView selectedClient={selectedClient} statusShareScreenClient={statusShareScreenClient} />

                    {/* Current Customers */}
                    <CurrentVisitors
                        selectedClient={selectedClient}
                        setSelectedClient={setSelectedClient}
                        statusShareScreenClient={statusShareScreenClient}
                        setStatusShareScreenClient={setStatusShareScreenClient}
                        setLoading={setLoading}
                    />

                    <BookedServiceCalls />
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default Dashboard;
