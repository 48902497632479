import ButtonToggle from "components/button-toggle";
import './style.scss'

interface IactivateAll {
    activateAll?: ()=> void;

}

const PageToolbar = (props:IactivateAll) => {
    return (
        <div className="page-toolbar">
            <h4>Current Triggers</h4>
            <div className="right">
                {/* <form onChange={handleSubmit}> */}
                {/* <ButtonToggle
                    defaultChecked={false}
                    setId={''}
                    fucntionChange={(e) => {
                        props.activateAll
                    }}
                />
                <h5>All Triggers Activated</h5> */}
                {/* </form> */}
            </div>
        </div>
    );
};

export default PageToolbar;
