import { axiosPrivate } from 'services/axios.private';
import { axiosPublic } from 'services/axios.public';

export class AuthApi {
    login = async (username: string, password: string) => {
        const res = await axiosPublic.post(`/api/v1/authentication/login`, {
            grant_type: 'password',
            username: username,
            password: password
        }).catch((err) => {
            return err;
        });
        return res.data;
    };
    fontendMe = async () => {
        let res = await  axiosPrivate.get(`/api/v2/frontend/me`).catch((err) => {
            return err;
        });
        return res;
    };
    forgotPassword = async (email: string) => {
        let res = await axiosPublic.post(`/api/v1/user/command/forgot-password-restore`, { email: email }).catch((err) => {
            return err;
        });
        return res;
    };
    resetPassword = async (password: string, email: string, code: string) => {
        let res = await axiosPublic.post(`/api/v1/user/command/restore-password-by-code`, {
            code: code,
            email: email,
            newPassword: password,
        }).catch((err) => {
            return err;
        });
        return res;
    };

    logout = () => {
        sessionStorage.removeItem('AI_sentBuffer');
        sessionStorage.removeItem('translation');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('AI_buffer');
    };
}
