import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
 
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '1cb86df4-cd76-4d6f-8901-4d29afb55f6e',
        extensions: [reactPlugin]
    }
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };